import React, { useEffect, useState, useRef, useMemo } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import useCountDown from 'react-countdown-hook';
import parse from 'react-html-parser';
import Keyboard from 'react-simple-keyboard';
import PinInput from 'react-pin-input';
import Draggable from 'react-draggable';
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane, 
} from "reactstrap";
import {
  WebUrl,
  ApiUrl,
  ApiKey,
  LanguageOption,
  SessionKey,
  Language,
  AlertTypes,
  DisplayDevice,
  _MAIN_WALLET_ID,
  PhoneNumberPrefix
} from "../util/Constant";
import ApiEngine from "../util/ApiEngine.js";
import { createFormBody, createMultiPartFormBody, numberWithCurrencyFormat, numberWithCurrencyFormat2, stringIsNullOrEmpty } from "../util/Util.js";
import { performLogin, performLogout, updateUserData } from "../redux/AuthAction.js";
import { setBusy, setIdle, showMessage, showResponseMessage, setThemeName, setBannerPopupImage, setBannerPopupModal } from "../redux/AppAction.js";
import { saveLanguageId } from "../redux/LanguageAction";
import SecureLS from "secure-ls";
import { isEmpty, map } from "lodash";
import moment from "moment";
import classnames from "classnames";
import Numpad2 from "../components/custom/Numpad2";
import BottomSuccessModal from "../components/custom/BottomSuccessModal";
import UMIcon from "../assets/img/icon/dashboardv2_um.png";
import PacgorLogo from '../assets/img/footer/pacgor_logo_white.png';
import PacgorLogoLT from '../assets/img/v3/footer/PAGGOR-black.svg';
import ProviderUMIcon from '../assets/img/icon/under_maintenance.svg';
import ProviderNEWIcon from '../assets/img/icon/New-top-right.svg';
import ProviderHOTIcon from '../assets/img/icon/hot-top-right.svg';
import LiveChatModal from '../components/custom/LiveChatModal';
import CustomSpinWheelContainer from '../components/custom/CustomSpinWheelContainer';
import Icon from '@mdi/react';
import { mdiCalendarRange } from '@mdi/js';
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
import DownloadApp from '../components/custom/DownloadApp';
import refreshIcon from "../assets/img/v3/spin-refresh-icon.gif";
import refreshIconStatic from "../assets/img/v3/spin-refresh-icon-static.png";
import CompleteIcon from "../assets/img/v3/complete_Icon.gif";
import BannerPopupModal from '../components/custom/BannerPopupModal';
import { startGame, setProductCategory, setActiveSoloGame } from "../redux/GameAction.js";
import NonSeamlessGameWalletModal from "../components/custom/NonSeamlessGameWalletModal";
import ProgressiveJackpot from "../components/custom/ProgressiveJackpot";
import MegatotoQuickBet from "../components/custom/MegatotoQuickBet";


/// <summary>
/// Author: -
/// </summary>
const Login = (props) => {
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  const ls = new SecureLS();
  const _history = useHistory();
  var { isLoggedIn } = useSelector((state) => state.authState);
  var _userData = useSelector((state) => state.authState.userData);
  const closedEye = themeIcons.eyeblocked;
  const openEye = themeIcons.eye;
  const themeName = useSelector((state) => state.appState.themeName);
  const liveChat = useSelector((state) => state.appState.liveChat);


  const [activeLanguageId, setActiveLanguageId] = useState(1);
  const [loginFailCount, setLoginFailCount] = useState(0);
  const [activeProvider, setActiveProvider] = useState(0);
  const [activeProviderName, setActiveProviderName] = useState("");
  const [providerListRowNum, setProviderListRowNum] = useState(1);
  const [allAvailableProvidersList, setAllAvailableProvidersList] = useState([]);
  const [currentWalletBalance, setCurrentWalletBalance] = useState(0);
  const [unclaimComm, setUnclaimComm] = useState("");
  const [grandTotalRewards, setGrandTotalRewards] = useState("");
  const [newGames, setNewGames] = useState({});
  const [newGamesIsLoading, setNewGamesIsLoading] = useState(false);
  const [recommendedGames, setRecommendedGames] = useState({});
  const [mainBannerData, setMainBannerData] = useState([]);
  const [subBannerData, setSubBannerData] = useState([]);
  const [promoBannerData, setPromoBannerData] = useState([]);
  const [allWalletData, setAllWalletData] = useState([]);
  const [providerWalletData, setProviderWalletData] = useState({});
  const [mpData, setMpData] = useState([]);
  const [gameIsApp, setGameIsApp] = useState(false);
  //const [gameHasLobby, setGameHasLobby] = useState(false);
  const [refreshWalletIsSpining, setRefreshWalletIsSpining] = useState(false);
  const [gameAppUrl, setGameAppUrl] = useState("");
  const [gameAppDeepLink, setGameAppDeepLink] = useState("");
  const [gameAppPackageNameKeyword, setGameAppPackageNameKeyword] = useState("");
  const [masterProductId, setMasterProductId] = useState(0);
  //const [activeSoloGame, setActiveSoloGame] = useState({});
  const [categoryActive, setCategoryActive] = useState("All");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentInput, setCurrentInput] = useState();
  const [paidAmount, setPaidAmount] = useState();
  const [transferDate, setTransferDate] = useState();
  const [isTransferSuccess, setIsTransferSuccess] = useState(false);
  const [transcactionNumber, setTranscactionNumber] = useState();
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loadingSearchData, setLoadingSearchData] = useState(false);
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);
  const [newForgotLoginPasswordVisible, setNewForgotLoginPasswordVisible] = useState(false);
  const [confirmForgotLoginPasswordVisible, setConfirmForgotLoginPasswordVisible] = useState(false);
  const [newLoginPasswordVisible, setNewLoginPasswordVisible] = useState(false);
  const [confirmLoginPasswordVisible, setConfirmLoginPasswordVisible] = useState(false);
  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(false);
  const [registerDetailsModalVisible, setRegisterDetailsModalVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [walletModalVisible, setWalletModalVisible] = useState(false);
  const [walletAppModalVisible, setWalletAppModalVisible] = useState(false);
  const [successPasswordModalVisible, setSuccessPasswordModalVisible] = useState(false);
  const [successRegisterModalVisible, setSuccessRegisterModalVisible] = useState(false);
  const [forgotPasswordSteps, setForgotPasswordSteps] = useState(1);
  const [phoneNum, setPhoneNum] = useState("");
  const [tempPhoneNum, setTempPhoneNum] = useState("");
  const [username, setUsername] = useState("");
  const initialTime = 120 * 1000;
  const interval = 1000;
  const [countdownTAC, { start, pause, resume, reset }] = useCountDown(initialTime, interval);
  const _MIN_USERNAME_LENGTH = 4;
  const _MIN_PASSWORD_LENGTH = 6;
  const keyboard = useRef();
  const elePin = useRef(null);
  const eleConfirm = useRef(null);
  const eleFinalPin = useRef(null);
  const [showPinEntry, setShowPinEntry] = useState(false);
  const [showVipPinEntry, setShowVipPinEntry] = useState(false);
  const [pinNumber, setPinNumber] = useState("");
  const [pinNumberConfirm, setPinNumberConfirm] = useState("");
  const [pinNumberFocused, setPinNumberFocused] = useState(0);
  const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
  const [pinNumberFinalFocused, setPinNumberFinalFocused] = useState(0);
  const [pinNumberFinal, setPinNumberFinal] = useState("");
  const [enteredPinNumber, setEnteredPinNumber] = useState("");
  const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
  const [enteredPinNumberFinal, setEnteredPinNumberFinal] = useState("");
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [pinEntered, setPinEntered] = useState(false);
  const [agreeTnC, setAgreeTnC] = useState(false);
  const [agreeAge18, setAgreeAge18] = useState(false);
  const [showTnC, setShowTnC] = useState(false);
  const [registerFullName, setRegisterFullName] = useState("");
  const [registerUsername, setRegisterUsername] = useState("");
  const [registerPhoneNumber, setRegisterPhoneNumber] = useState("");
  const [selectedPhonePrefix, setSelectedPhonePrefix] = useState("60");
  const [showPhoneText, setShowPhoneText] = useState(false);
  const [prevPhoneNumber, setPrevPhoneNumber] = useState("");
  const [getPointsDone, setGetPointsDone] = useState(false);
    const userId = useSelector(
        (state) => state.authState.userData.userId
    );
    const loyaltyPoints = useSelector(
        (state) => state.authState.userData.loyaltyPoints
    );
  const [passTAC, setPassTAC] = useState(false);
  const [refreshBalanceSpin, setRefreshBalanceSpin] = useState(false);
  const [myTimer, setMyTimer] = useState(null);
  const [isHidden, setIsHidden] = useState(closedEye);
  const [isHiddenRegister, setIsHiddenRegister] = useState(closedEye);
  const [isHiddenConfirm, setIsHiddenConfirm] = useState(closedEye);
  const [isHiddenForgot, setIsHiddenForgot] = useState(closedEye);
  const [isHiddenForgotConfirm, setIsHiddenForgotConfirm] = useState(closedEye);

  const [referralCode, setReferralCode] = useState("");
  const [uplineDetail, setUplineDetail] = useState({});
  const [navList1, setNavList1] = useState([]);
  const [forgetPin, setForgetPin] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [smsSent, setSmsSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [invalidPin, setInvalidPin] = useState(false);

  const [activeLanguage, setActiveLanguage] = useState("en");
  const [TACtimedOut, setTACtimedOut] = useState(true);
  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const _TAC_VALID_TIMER = 300000;
  const _SECONDS_IN_MS = 1000;

  const [navVisible, setNavVisible] = useState(false);
  const [content, setContent] = useState("");
  const [showCreateShareholderPin, setShowCreateShareholderPin] = useState(false);
  const [showTncError, setShowTncError] = useState(false);
  const [showAgeError, setShowAgeError] = useState(false);

  // For Mini Game 
  const miniGameRef = useRef(null);
  const miniGameCloseButtonRef = useRef(null);
  const [openMiniGameModal, setOpenMiniGameModal] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [currentActiveTab, setCurrentActiveTab] = useState("COINMINI");
  const [coinMiniGameUrl, setCoinMiniGameUrl] = useState("");
  const [kenoMiniGameUrl, setKenoMiniGameUrl] = useState("");
  const [taiXiuMiniGameUrl, setTaiXiuMiniGameUrl] = useState("");
  const [miniGamesFirstClicked, setMiniGamesFirstClicked] = useState(["COINMINI"]);
  const [showMiniGameTrigger, setShowMiniGameTrigger] = useState(JSON.parse(localStorage.getItem(SessionKey._ISSHOWMINIGAMETRIGGER)));
  const [controlledPosition, setControlledPosition] = useState({});
  const [startGameTime, setStartGameTime] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [games93Connect, setGames93Connect] = useState({});
  const delayTime = 4000;
  const onControlledDrag = (e, position) => {
  const { x, y } = position;
    setControlledPosition({ x, y });
  }
  // End For Mini Game 

  const search = window.location.search;
  const params = new URLSearchParams(search);

  const [isRecommendedMobileGame, setIsRecommendedMobileGame] = useState({});
  const [isGameForYouMobileGame, setIsGameForYouMobileGame] = useState({});
  const [isGettingGames, setIsGettingGames] = useState(true);

  const [tncContent, setTncContent] = useState("");
  const [isChangeCountry, setIsChangeCountry] = useState(false);
  const [showFreeSpin, setShowFreeSpin] = useState(false);
  const [freeSpinDone, setFreeSpinDone] = useState(false);
  const [freeSpinContent, setFreeSpinContent] = useState([]);
  const [freeSpinCount, setFreeSpinCount] = useState(0);

  const [recommendedMatches, setRecommendedMatches] = useState([]);
  const [isLoadingRecommendedMatches, setIsLoadingRecommendedMatches] = useState(true);
  const [isFirstLoadingRecommendedMatches, setIsFirstLoadingRecommendedMatches] = useState(true);
  const [selectedRecommendedMatch, setSelectedRecommendedMatch] = useState({});
  const [memberPoint, setMemberPoint] = useState(0);

  //const [isSpecialRollover, setIsSpecialRollover] = useState(false);
  //const [currentRolloverBalance, setCurrentRolloverBalance] = useState(_userData.rolloverBalance);
  //const [rolloverTarget, setRolloverTarget] = useState(0);
  //const [playableCategory, setPlayableCategory] = useState([]);
  //const [productCategory, setProductCategory] = useState([]);
  const [productCategory2, setProductCategory2] = useState([]);
  const [isTodayCheckIn, setIsTodayCheckIn] = useState(true);
  const [showCheckIn, setShowCheckIn] = useState(false);

  const [isSportEventUM, setIsSportEventUM] = useState(false);
  const [SBOSportsGameId, setSBOSportsGameId] = useState(0);
  const [registerPopupContent, setRegisterPopupContent] = useState("");
  const [registerWhatsAppUrl, setRegisterWhatsAppUrl] = useState("");
  const _APPEND_REGISTER_WHATSAPP_URL = "?text=register%20";
  //const [companyContentList, setCompanyContentList] = useState([]);
  const _DEFAULT_PROVIDER_COUNT = 12;
  const [isShowMoreProvider, setIsShowMoreProvider] = useState(false);
  const { productCategory, rolloverTarget, isSpecialRollover, playableCategory, showNonSeamlessTransferConfirmation, currentRolloverBalance, rolloverProcess } = useSelector((state) => state.gameState);
  const [betInfo, setBetInfo] = useState([]);

  const verifyState = {
    button: 1
  }

  const {
    register: forgotPasswordRegister,
    handleSubmit: forgotPasswordHandleSubmit,
    errors: forgotPasswordErrors,
    unregister: forgotPasswordUnregister
  } = useForm();

  const {
    register: tacVerifyRegister,
    handleSubmit: tacVerifyHandleSubmit,
    unregister: tacVerifyUnregister
  } = useForm();

  const {
    register: resetPasswordRegister,
    handleSubmit: resetPasswordHandleSubmit,
    errors: resetPasswordErrors,
    watch
  } = useForm();

  const {
    register,
    handleSubmit: registerHandleSubmit,
    errors: registerErrors
  } = useForm({ mode: "onBlur" });

  const {
    register: generatePasswordRegister,
    handleSubmit: generatePasswordHandleSubmit,
    errors: generatePasswordErrors,
    watch: generatePasswordWatch,
  } = useForm({ mode: "onBlur" });


  const [registerDetailsSteps, setRegisterDetailsSteps] = useState(1);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectionOption, setSelectionOption] = useState([]);

  const [showNavCountry, setShowNavCountry] = useState(false);
  const [selectionNavOption, setSelectionNavOption] = useState([]);
  const [selectionLangHeaderOption, setSelectionLangHeaderOption] = useState([]);
  const [masterProviderList, setMasterProviderList] = useState({});
  const [isLoadingMemberDatas, setIsLoadingMemberDatas] = useState(false);
  const [isLoadingRolloverDatas, setIsLoadingRolloverDatas] = useState(false);
  const {
    register: loginRegister,
    handleSubmit: loginHandleSubmit,
    errors: loginErrors,
  } = useForm();
  const {
    register: registerPopupTransfer,
    handleSubmit: handleSubmitPopupTransfer,
    setValue: setValuePopupTransfer,
    getValues: getValuesPopupTransfer,
    watch: watchPopupTransfer
  } = useForm();

  const PROVIDERS_PER_ROW = 6;
  const _COUNTDOWN_PERIOD = 120;
  const _ALERT_TIMEOUT = 3000;

  let usernameRef = useRef();
  let passwordRef = useRef();

  let tempInterval;
  var timeoutRef;
  const get568RecommendedMatchesInterval = useRef(null);
  const rolloverFlagInterval = useRef(null);
  const rolloverInterval = useRef(null);
  const walletBalanceInterval = useRef(null);

  const bannerSlickSlidesToShow = 1;
  const bannerSlick = {
    slidesToShow: 1,
    dots: true,
    arrows: false,
    swipe: true,
    infinite: true,
    swipeToSlide: true,
    speed: 1000,
    autoplay: true,
  };
  const popularSlick = {
    slidesToShow: 3,
    dots: false,
    arrows: false,
    swipe: true,
    infinite: true,
    swipeToSlide: true,
    speed: 1000,
    autoplay: true,
  };

  const promoBannerSlick = {
    infinite: true,
    swipe: true,
    swipeToSlide: true,
    dots: false,
    arrows: false,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 3000
  };

  const navList2 = [
    {
      text: t("LIVE_PROMOTION"),
      icon: require("../assets/img/v3/vip_event.svg"),
      href: WebUrl._URL_PROMOTION,
      vicon: themeIcons.promotion,
    },
    {
      text: t("ABOUT_US"),
      icon: require("../assets/img/v3/about.svg"),
      href: WebUrl._URL_ABOUT.replace(":category", "about-us"),
      vicon: themeIcons.about,
    },
    {
      text: "CHECK_IN",
      icon: require("../assets/img/v3/coin-white.svg"),
      href: WebUrl._URL_DAILY_CHECK_IN,
      vicon: themeIcons.dailyCheckIn
    },
    {
      text: t("BIG_WINNING"),
      icon: require("../assets/img/v3/bigwinning_icon.svg"),
      href: WebUrl._URL_BIG_WINNING,
      vicon: themeIcons.about,
    },
    {
      text: t("HELP_CENTRE"),
      icon: require("../assets/img/v3/help.svg"),
      href: WebUrl._URL_HELP_CENTRE,
      vicon: themeIcons.settingsLivechat,

    },
    {
      text: t("AFFILIATE_PARTNER_PROGRAM"),
      icon: require("../assets/img/v3/afffilate.svg"),
      href: WebUrl._URL_ABOUT.replace(":category", "affiliate-partner"),
      vicon: themeIcons.ugroup,

    },
    {
      text: t("PRIVACY_POLICY"),
      icon: require("../assets/img/v3/privacy.svg"),
      href: WebUrl._URL_ABOUT.replace(":category", "privacy-policy"),
      vicon: themeIcons.lock1,

    },
    {
      text: t("TERMS_AND_CONDITIONS"),
      icon: require("../assets/img/v3/terms.svg"),
      href: WebUrl._URL_ABOUT.replace(":category", "terms-and-conditions"),
      vicon: themeIcons.tc,

    },
    {
      text: t("RESPONSIBLE_GAMBLING"),
      icon: require("../assets/img/v3/gambling.svg"),
      href: WebUrl._URL_ABOUT.replace(":category", "responsible-gambling"),
      vicon: themeIcons.rolloverReport,
    },
  ];
  const socialMediaList = [
    {
      image: require("../assets/img/v3/fb.png"),
      link: 'https://www.facebook.com/i8OfficialTeam',
    },
    {
      image: require("../assets/img/v3/ig.png"),
      link: 'https://www.instagram.com/i8.live/?hl=en',
    },
    {
      image: require("../assets/img/v3/yt.png"),
      link: 'https://www.youtube.com/channel/UCxrKFaJx8jENt4hjrnKtYiQ',
    },
  ];
  const gameForYouList = [
    {
      categoryTitle: "RNG",
      gameName: "Keno",
      gameUm: false,
      hasOwnLobby: false,
      id: 3,
      image: "https://cdn.i8global.com/i8app/180x180_eng_ind_vt-202304180810578899.png",
      isNewGame: false,
      isRecommended: false,
      providerName: "93Connect",
      rank: 1
    },
    {
      categoryTitle: "Slots",
      gameName: "Sweet Bonanza",
      gameUm: false,
      hasOwnLobby: true,
      id: process.env.REACT_APP_ENV === "Staging" ? 4660 : 4536,
      image: "https://cdn.i8global.com/i8app/vs20fruitsw-202304171656428462.png",
      isNewGame: false,
      isRecommended: false,
      providerName: "Pragmatic Play Slots",
      rank: 1
    },
    {
      categoryTitle: "RNG",
      gameName: "Thai HiLo",
      gameUm: false,
      hasOwnLobby: false,
      id: 3,
      image: "https://cdn.i8global.com/i8app/thl_en_id_180x180-202304180811313237.jpg",
      isNewGame: false,
      isRecommended: false,
      providerName: "93Connect",
      rank: 1
    },
    {
      categoryTitle: "Slots",
      gameName: "Gate of Gatot Kaca",
      gameUm: false,
      hasOwnLobby: true,
      id: process.env.REACT_APP_ENV === "Staging" ? 4661 : 4537,
      image: "https://cdn.i8global.com/i8app/vs20gatotgates-202304171659560122.png",
      isNewGame: false,
      isRecommended: false,
      providerName: "Pragmatic Play Slots",
      rank: 1
    },
    {
      categoryTitle: "Slots",
      gameName: "Fortune Tiger",
      gameUm: false,
      hasOwnLobby: true,
      id: process.env.REACT_APP_ENV === "Staging" ? 4640 : 4695,
      image: "https://cdn.i8global.com/i8app/fortune-tiger-202305111344195621.jpg",
      isNewGame: false,
      isRecommended: false,
      providerName: "PGSoft",
      rank: 1
    },
  ];
  const pointsList = [
    {
      title: t("COMMISSION"),
      points: unclaimComm == "" ? t("LOADING") + "... " : " " + numberWithCurrencyFormat2(parseFloat(unclaimComm.totalClaimable), 2, true) + " ",
      image: "/img/v3/commission.svg",
      url: WebUrl._URL_UNCLAIM_COMMISSION_HISTORY,
      tooltipText: "COMMISSION_DETAIL",
      icon: themeIcons.commission,
    },
    {
      title: t("REWARDS"),
      points: grandTotalRewards === "" ? t("LOADING") + "... " : " " + numberWithCurrencyFormat2(parseFloat(grandTotalRewards), 2, true) + " ",
      image: "/img/v3/reward.svg",
      url: WebUrl._URL_REWARDS,
      tooltipText: "REWARDS_DETAIL",
      icon: themeIcons.reward,
    },
    {
      title: t("LOYALTY"),
      points: isLoggedIn ? numberWithCurrencyFormat2(parseFloat(memberPoint), 2, true) : "-",
      image: "/img/v3/points.svg",
      url: WebUrl._URL_LOYALTY_REDEMPTION,
      tooltipText: "GAME_POINT_DETAIL",
      icon: themeIcons.loyaltyPoints
    },
  ];
  const buttonList = [
    {
      name: t("WITHDRAW"),
      image: require("../assets/img/v3/withdraw.svg"),
      href: WebUrl._URL_WITHDRAWAL,
      icon: themeIcons.withdraw,
    },
    {
      name: t("TRANSFER"),
      image: require("../assets/img/v3/transfer.svg"),
      href: WebUrl._URL_TRANSFER,
      icon: themeIcons.transfer1,
    },
    {
      name: t("KPI"),
      image: require("../assets/img/v3/kpi.svg"),
      href: WebUrl._URL_KPI,
      icon: themeIcons.kpi,

    },
    //{
    //    name: t("4D_RESULT"),
    //    image: require("../assets/img/v3/4d-result.svg"),
    //    href: WebUrl._URL_GAME_RESULT,

    //},
    {
      name: t("DOWNLINE"),
      image: require("../assets/img/v3/downline.svg"),
      href: WebUrl._URL_DOWNLINE,
      icon: themeIcons.downline,

    },
    {
      name: t("REPORTS"),
      image: require("../assets/img/v3/report.svg"),
      href: WebUrl._URL_REPORTS,
      icon: themeIcons.report,
    },
    // {
    //   name: "Downlines",
    //   image: require("../assets/img/v3/more.png"),
    //   href: WebUrl._URL_DOWNLINE,

    // },
    // {
    //   name: "Rewards",
    //   image: require("../assets/img/v3/kpi.png"),
    //   href: WebUrl._URL_REWARDS,

    // },
    // {
    //   name: "Referral",
    //   image: require("../assets/img/v3/4d.png"),
    //   href: WebUrl._URL_MANAGE_REFERRAL_CODES,

    // },

  ];
  const countryList = [
    {
      name: "Malaysia",
      iso: "MYR",
      icon: require("../assets/img/v3/my.png"),
      icon_circle: require("../assets/img/v3/my-circle.png"),
      url: window.location.origin,//((window.location.href).includes("staging") || (window.location.href).includes("localhost")) ? 'https://my-staging.i8global.com' : (window.location.href).includes("preproduction") ? 'https://my-preproduction.i8global.com' : 'https://my-preproduction.i8global.com',
      child_languages: [
        {
          name: "English",
          languageCode: Language._ENGLISH,
          languageId: 1
        },
        {
          name: "中文",
          languageCode: Language._CHINESE,
          languageId: 2
        },
        {
          name: "Malay",
          languageCode: Language._BAHASA_MELAYU,
          languageId: 3
        },
        {
          name: "ไทย",
          languageCode: Language._THAI,
          languageId: 5
        },
        {
          name: "Tiếng Việt",
          languageCode: Language._VIETNAMESE,
          languageId: 6
        },
        {
          name: "Indo",
          languageCode: Language._INDON,
          languageId: 7
        }
      ],
    },
    {
      name: "Thailand",
      iso: "TH",
      icon: require("../assets/img/v3/th.png"),
      icon_circle: require("../assets/img/v3/th-circle.png"),
      url: ((window.location.href).includes("staging") || (window.location.href).includes("localhost")) ? 'https://th-staging.huat8th.com' : (window.location.href).includes("preproduction") ? 'https://th-preproduction.huat8th.com' : 'https://m.i8th.live',
      child_languages: [
        {
          name: "ไทย",
          languageCode: Language._THAI,
          languageId: 5
        },
        {
          name: "English",
          languageCode: Language._ENGLISH,
          languageId: 1
        },
        {
          name: "中文",
          languageCode: Language._CHINESE,
          languageId: 2
        },
        {
          name: "Malay",
          languageCode: Language._BAHASA_MELAYU,
          languageId: 3
        },
        {
          name: "Tiếng Việt",
          languageCode: Language._VIETNAMESE,
          languageId: 6
        },
        {
          name: "Indo",
          languageCode: Language._INDON,
          languageId: 7
        }
      ],
    },
    {
      name: "Indonesia",
      iso: "ID",
      icon: require("../assets/img/v3/id.png"),
      icon_circle: require("../assets/img/v3/id-circle.png"),
      url: ((window.location.href).includes("staging") || (window.location.href).includes("localhost")) ? 'https://id-staging.huat8id.com/' : (window.location.href).includes("preproduction") ? 'https://id-preproduction.huat8id.com/' : 'https://m.i8id.live/',
      child_languages: [
        {
          name: "Indo",
          languageCode: Language._INDON,
          languageId: 7
        },
        {
          name: "English",
          languageCode: Language._ENGLISH,
          languageId: 1
        },
        //{
        //  name: "中文",
        //  languageCode: Language._CHINESE,
        //  languageId: 2
        //},
        //{
        //  name: "Malay",
        //  languageCode: Language._BAHASA_MELAYU,
        //  languageId: 3
        //},
        //{
        //  name: "ไทย",
        //  languageCode: Language._THAI,
        //  languageId: 5
        //},
        //{
        //  name: "Tiếng Việt",
        //  languageCode: Language._VIETNAMESE,
        //  languageId: 6
        //},
      ],
    },
    {
      name: "Australia",
      iso: "AU",
      icon: require("../assets/img/v3/au.png"),
      icon_circle: require("../assets/img/v3/au-circle.png"),
      url: ((window.location.href).includes("staging") || (window.location.href).includes("localhost")) ? 'https://au-staging.huat8au.com' : (window.location.href).includes("preproduction") ? 'https://au-preproduction.huat8au.com' : 'https://m.i8au.live',
      child_languages: [
        {
          name: "English",
          languageCode: Language._ENGLISH,
          languageId: 1
        },
        {
          name: "中文",
          languageCode: Language._CHINESE,
          languageId: 2
        },
        {
          name: "Malay",
          languageCode: Language._BAHASA_MELAYU,
          languageId: 3
        },
        {
          name: "ไทย",
          languageCode: Language._THAI,
          languageId: 5
        },
        {
          name: "Tiếng Việt",
          languageCode: Language._VIETNAMESE,
          languageId: 6
        },
        {
          name: "Indo",
          languageCode: Language._INDON,
          languageId: 7
        }
      ],
    },
  ];

  const certList = [
    {
      alt: "BMM",
      image: require("../assets/img/footer/cert-bmm-white.png"),
      imageLight: require("../assets/img/v3/footer/Bmm.svg")
    },
    {
      alt: "GoDaddy",
      image: require("../assets/img/footer/cert-godaddy-white.svg"),
      imageLight: require("../assets/img/v3/footer/Godaddy.svg")
    },
    {
      alt: "TST",
      image: require("../assets/img/footer/cert-tst-white.svg"),
      imageLight: require("../assets/img/v3/footer/tst.svg")
    },
    {
      alt: "GLI",
      image: require("../assets/img/footer/cert-gli-white.svg"),
      imageLight: require("../assets/img/v3/footer/GLI.svg")
    },
    {
      alt: "iTechLab",
      image: require("../assets/img/footer/cert-itechlab-white.svg"),
      imageLight: require("../assets/img/v3/footer/itechlabs.svg")
    },
  ];

  const securityList = [
    {
      alt: "Iovation",
      image: require("../assets/img/footer/security-iovation-white.svg"),
      imageLight: require("../assets/img/v3/footer/iovation.svg")
    },
    {
      alt: "ThreatMetrix",
      image: require("../assets/img/footer/security-threatmetrix-white.svg"),
      imageLight: require("../assets/img/v3/footer/threatmetrix.svg")
    },
  ];

  const recommendedBrowserList = [
    {
      alt: "Crome",
      image: require("../assets/img/footer/rb-chrome-white.png"),
      imageLight: require("../assets/img/v3/footer/Chrome.svg")
    },
    {
      alt: "Firefox",
      image: require("../assets/img/footer/rb_firefox_white.png"),
      imageLight: require("../assets/img/v3/footer/firefox.svg")
    },
  ];

  const footerSocialMediaList = [
    {
      alt: "facebook",
      image: require("../assets/img/footer/sm_fb_white.png"),
      link: "https://www.facebook.com/i8OfficialTeam",
      imageLight: require("../assets/img/v3/footer/facebook.svg")
    },
    {
      alt: "youtube",
      image: require("../assets/img/footer/sm_yt_white.png"),
      link: "https://www.youtube.com/channel/UCxrKFaJx8jENt4hjrnKtYiQ",
      imageLight: require("../assets/img/v3/footer/youtube.svg")
    },
    {
      alt: "instagram",
      image: require("../assets/img/footer/sm_ig_white.png"),
      link: "https://instagram.com/i8.live",
      imageLight: require("../assets/img/v3/footer/ig.svg")
    },
  ];

  //useEffect
  useEffect(() => {
    let initSelectionOption = countryList.filter(x => x.iso == "MYR")[0];
    setSelectionOption(initSelectionOption);
    setSelectionNavOption(initSelectionOption);
    setSelectionLangHeaderOption(initSelectionOption);

    getQuickBetInfos();

    if (params.get("languageCode") != null) {
      changeLanguage(params.get("languageCode"));
      setActiveLanguage(params.get("languageCode"));
      let tempLangId = LanguageOption.filter(x => x.code == params.get("languageCode"))[0].languageId;
      setActiveLanguageId(tempLangId);
      localStorage.setItem(SessionKey._LANGUAGE, params.get("languageCode"));
    }
    else if (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) !== "undefined") {
      let tempLangId = LanguageOption.filter(x => x.code == localStorage.getItem(SessionKey._LANGUAGE))[0].languageId;
      setActiveLanguageId(tempLangId);
      setActiveLanguage(localStorage.getItem(SessionKey._LANGUAGE));
    }
    else {
      localStorage.setItem(SessionKey._LANGUAGE, "en");
      setActiveLanguage("en");
      setActiveLanguageId(1);
    }

    const parsedReferralCode = params.get('code');
    if (parsedReferralCode != null) {
      getUpline(parsedReferralCode);
      }
  }, []);

  useEffect(() => {
    if (isChangeCountry) {
      //do nothing
    }
    else if (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) !== "undefined") {
      let tempLangCode = LanguageOption.filter(x => x.languageId == activeLanguageId)[0].code;
      if (localStorage.getItem(SessionKey._LANGUAGE) == tempLangCode) {
        getDashboardGames();
        getIsRecommandedGame();
        getCompanyContent();
        getBanners();
        getMarqueeTagContent();
      }
    }
    else {
      localStorage.setItem(SessionKey._LANGUAGE, "en");
      setActiveLanguage("en");
      setActiveLanguageId(1);

    }
  }, [activeLanguageId]);

  useEffect(() => {
    if (countdownPeriod == 300) {
      clearTimeout(myTimer);
    }
    if (countdownPeriod > 0) {

      let timer = setTimeout(() => {
        let tempPeriod = countdownPeriod - 1;
        setCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS);

      //myTimer = timer;
      setMyTimer(timer);

    } else {
      setTACtimedOut(true);
    }
  }, [countdownPeriod]);

    useEffect(() => {
        console.log(refreshBalanceSpin);
    }, [refreshBalanceSpin]);


    useEffect(() => {
        console.log(refreshWalletIsSpining);
    }, [refreshWalletIsSpining]);

  useEffect(() => {
    getProductCategory2();
    if (isLoggedIn) {
          getCheckInModuleStatus();
          checkTodayCheckInStatus();
          getMemberLoyaltyPoints();
          //getMemberSpecialRollover();
          //getProductCategory();
      let tempNavList1 = [];
      //getMemberSpecialRollover();
      //getProductCategory();
      if (_userData.isVip) {
        tempNavList1.push(
          {
            text: "VIP_EVENT",
            icon: require("../assets/img/v3/vip_event.svg"),
            url: WebUrl._URL_SHAREHOLDER + "?GAzobE73Vse4nr8WpufA=" + _userData.hideSummary,
            vicon: themeIcons.star,
          }
        );
      };
      tempNavList1.push(
        {
          text: "NOTIFICATION",
          icon: require("../assets/img/v3/notification.svg"),
          url: WebUrl._URL_NOTIFICATION,
          vicon: themeIcons.bell,
        },
        //{
        //    text: "DOWNLINES",
        //    icon: require("../assets/img/v3/nav-right-downlines.svg"),
        //    url: WebUrl._URL_DOWNLINE
        //},
        {
          text: "4D_RESULT",
          icon: require("../assets/img/v3/4d-result-white.svg"),
          url: WebUrl._URL_GAME_RESULT,
          vicon: themeIcons.result4d,
        },
        {
          text: "VIDEO_TUTORIAL",
          icon: require("../assets/img/v3/video_tutorial.svg"),
          url: WebUrl._URL_VIDEO_TUTORIAL,
          vicon: themeIcons.play,
        },
        // {
        //     text: "LIVE_CHAT",
        //     icon: require("../assets/img/v3/live_chat.svg"),
        //     url: WebUrl._URL_LIVE_CHAT
        // },
        {
          text: "LIVE_CHAT",
          icon: require("../assets/img/v3/live_chat.svg"),
          url: WebUrl._URL_CONTACT_US,
          vicon: themeIcons.livechat,
        },
        //{
        //  text: "TNC_SHORT",
        //  icon: require("../assets/img/v3/tnc.svg"),
        //  url: WebUrl._URL_TNC
        //},
        {
          text: "FAVORITE_GAME",
          icon: require("../assets/img/v3/white-favorite.svg"),
          url: WebUrl._URL_FAVORITE_GAME,
          vicon: themeIcons.favorite,
        },
        {
          text: "SETTINGS",
          icon: require("../assets/img/v3/settings.svg"),
          url: WebUrl._URL_SETTINGS,
          vicon: themeIcons.settings,
        }
      );

      setNavList1(tempNavList1);
      getUnclaimCommHistory();
      getRewardData();
      updateUserWalletBalance();
      if (_userData.isVip) {
        checkMemberShareholderPin();
      }
      checkDailyCheckin(tempNavList1);
      setIsLoadingMemberDatas(false);
    }
    else {
      setShowMiniGameTrigger(false);
      localStorage.setItem(SessionKey._ISSHOWMINIGAMETRIGGER, false);
      handleLogout();
      //setPlayableCategory([]);
      //setRolloverTarget(0);
      //_dispatch(setPlayableCategory([]));
      //_dispatch(setRolloverTarget(0));
      clearTimeout(walletBalanceInterval.current);
    }
    getDashboardGames();
    getIsRecommandedGame();
    //getMemberLoyaltyPoints();
    return () => {
      clearInterval(walletBalanceInterval.current);
      clearInterval(rolloverFlagInterval.current);
      clearInterval(rolloverInterval.current);
    };
  }, [isLoggedIn]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem(SessionKey._ISONLOAD)) === true) {
      localStorage.setItem(SessionKey._ISONLOAD, false);
    }
    else if (JSON.parse(localStorage.getItem(SessionKey._ISONLOAD)) === false) {
      if (_history['action'] == "POP") {
        localStorage.setItem(SessionKey._ISSHOWMINIGAMETRIGGER, true);
        setShowMiniGameTrigger(true);
      }
    }
  }, []);

  window.onbeforeunload = function () {
    localStorage.setItem(SessionKey._ISONLOAD, true);
  };

  useEffect(() => {
    const scrollEvent = () => {
      window.addEventListener("scroll", (e) => {
        if (miniGameRef.current != null && miniGameCloseButtonRef.current != null) {
          miniGameRef.current.style.position = "fixed";
          miniGameCloseButtonRef.current.style.position = 'fixed';
        }

      });
    };
    scrollEvent();

    return () => {
      //do nothing
    };
  }, []);

  useEffect(() => {
    get568RecommendedMatches();
    getIsRecommandedGame();
    getCompanyContent();
  }, []);

  const getMemberLoyaltyPoints = async () => {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_LOYALTY_POINTS_BY_ID);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setMemberPoint(responseJson[ApiKey._API_DATA_KEY]);
    }
    _dispatch(setIdle());
    setGetPointsDone(true);
  }

  //useEffect(() => {
  //  if (isLoggedIn && isSpecialRollover) {
  //    getRolloverData();
  //    if (playableCategory.length === 0) { getBonusDepositProductCategory(); }
  //  }
  //  else {
  //    // do nothing
  //  }
  //  if (currentRolloverBalance === 0) {
  //    //setRolloverTarget(0);
  //    _dispatch(setRolloverTarget(0));
  //  }
  //}, [isSpecialRollover, currentRolloverBalance])

  useEffect(() => {
    if (isLoggedIn && isSpecialRollover) {
      //getRolloverData();
      //getMemberCurrentRolloverBalance();
      //getBonusDepositProductCategory();
      clearInterval(rolloverFlagInterval.current);
      clearInterval(rolloverInterval.current);
      //rolloverFlagInterval.current = setInterval(getMemberSpecialRollover, 5000);
      //rolloverInterval.current = setInterval(getMemberCurrentRolloverBalance, 5000);
    }
    else {
      // do nothing
    }
  }, [isSpecialRollover])

  //useEffect(() => {
  //  if (isLoggedIn && isSpecialRollover) {
  //    getRolloverData();
  //    if (playableCategory.length === 0) { getBonusDepositProductCategory(); }
  //  }
  //  else {
  //    // do nothing
  //  }
  //  if (currentRolloverBalance === 0) {
  //    //setRolloverTarget(0);
  //    _dispatch(setRolloverTarget(0));
  //  }
  //}, [isSpecialRollover, currentRolloverBalance])

  const getIsRecommandedGame = async () => {
    try {
      setNewGamesIsLoading(true);
      let apiUrl = ApiUrl._API_GET_IS_RECOMMENDED_MOBILE +
        "?languageId=" +
        activeLanguageId +
        "&Count=" +
        15;

      var newResponseJson = await ApiEngine.get(apiUrl);

      if (newResponseJson[ApiKey._API_SUCCESS_KEY]) {
        let recommendedGamesData = newResponseJson[ApiKey._API_DATA_KEY]["isRecommendedMobileGame"];
        setIsRecommendedMobileGame(recommendedGamesData);
        recommendedGamesData.map((game) => {
          let image = new Image();
          image.src = game["image"];

          window[game["image"]] = image;
        });

        let isGameForYouData = newResponseJson[ApiKey._API_DATA_KEY]["isGameForYouMobileGame"];
        setIsGameForYouMobileGame(isGameForYouData);
        isGameForYouData.map((game) => {
          let image = new Image();
          image.src = game["image"];

          window[game["image"]] = image;
        });
        setTimeout(() => setNewGamesIsLoading(false), 1000);
      } else {
        throw newResponseJson[ApiKey._API_MESSAGE_KEY];
      }
    } catch (errorMessage) {
      _dispatch(showResponseMessage(false, errorMessage));
    }
  }

  const get568RecommendedMatches = async () => {
    clearTimeout(get568RecommendedMatchesInterval.current);
    try {
      let apiUrl = ApiUrl._URL_GET_568_RECOMMENDED_MATCHES + "?languageId=" + activeLanguageId;
      var responseJson = await ApiEngine.get(apiUrl);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let data = responseJson[ApiKey._API_DATA_KEY];
        data = data.sort(function (a, b) {
          var dateA = new Date(a.kickOffTimeGMT);
          var dateB = new Date(b.kickOffTimeGMT);
          return dateA - dateB;
        });
        setRecommendedMatches(data);
      } else {
        setRecommendedMatches([]);
      }
      setIsLoadingRecommendedMatches(false);
    } catch {
      setIsLoadingRecommendedMatches(false);
    }
    get568RecommendedMatchesInterval.current = setTimeout(() => get568RecommendedMatches(), 30000);
  }

  //function
  const getDashboardGames = async () => {
    setIsGettingGames(true);
    try {
      setNewGamesIsLoading(true);
      let apiUrl =
        ApiUrl._API_GET_IS_NEW_MASTER_PRODUCT_GROUPED_GAME +
        "?languageId=" +
        activeLanguageId +
        "&Count=" +
        15;
      var newResponseJson = await ApiEngine.get(apiUrl);

      if (newResponseJson[ApiKey._API_SUCCESS_KEY]) {
        let newGamesData = newResponseJson[ApiKey._API_DATA_KEY]["isNewGame"];
        setNewGames(newGamesData);
        newGamesData.map((game) => {
          let image = new Image();
          image.src = game["image"];

          window[game["image"]] = image;
        });
        let recommendedGamesData =
          newResponseJson[ApiKey._API_DATA_KEY]["isReccommendedGame"];
        setRecommendedGames(recommendedGamesData);
        recommendedGamesData.map((game) => {
          let image = new Image();
          image.src = game["image"];

          window[game["image"]] = image;
        });
        setTimeout(() => setNewGamesIsLoading(false), 1000);
        let availableProductList = newResponseJson[ApiKey._API_DATA_KEY]["availableMasterProductList"].filter(x => !stringIsNullOrEmpty(x.productImage));
        setAllAvailableProvidersList(availableProductList);
        let SBOSports = availableProductList.filter(x => x.productName === "SBO Sports");
        if (SBOSports.length > 0) {
          setSBOSportsGameId(SBOSports[0].game.id);
          if (SBOSports[0].isUM === true) {
            setIsSportEventUM(true);
          }
        }

        //let listIndex = 1;
        let tempMasterProductList = {};
        //availableProductList.map((product) => {
        //  if (!tempMasterProductList["productList" + listIndex]) {
        //    tempMasterProductList["productList" + listIndex] = [];
        //  }
        //  if (tempMasterProductList["productList" + listIndex].length < PROVIDERS_PER_ROW) {
        //    tempMasterProductList["productList" + listIndex].push(product);
        //  }
        //  else {
        //    listIndex++;
        //    tempMasterProductList["productList" + listIndex] = [];
        //    tempMasterProductList["productList" + listIndex].push(product);
        //  }

        //  if (product.categoryName == "Connect") {
        //    setGames93Connect(product.productGame);
        //  }
        //});
        let providerCount = 0;
        if (availableProductList.length >= _DEFAULT_PROVIDER_COUNT && categoryActive != "All") {
          providerCount = 12;
          setIsShowMoreProvider(true);
        } else {
          providerCount = availableProductList.length;
          setIsShowMoreProvider(false);
        }
        
        setMasterProviderList(availableProductList.slice(0, providerCount));
      } else {
        throw newResponseJson[ApiKey._API_MESSAGE_KEY];
      }
    }
    catch (errorMessage) {
      _dispatch(showResponseMessage(false, errorMessage));
    }
    setTimeout(() => setIsGettingGames(false), 1);
  }

  const sortProviders = (categoryName) => {
    let tempAvailableProvidersList = allAvailableProvidersList;
    if (categoryName !== "All") {
      if (categoryName === "Slots") {
        tempAvailableProvidersList = tempAvailableProvidersList.filter(x => (x.categoryName === "Slots" || x.categoryName === "SlotMix") && !stringIsNullOrEmpty(x.productImage));
      } else {
        tempAvailableProvidersList = tempAvailableProvidersList.filter(x => x.categoryName === categoryName && !stringIsNullOrEmpty(x.productImage));
      }
    }
    //let listIndex = 1;
    //let tempMasterProductList = {};
    //tempAvailableProvidersList.map((product) => {
    //  if (!tempMasterProductList["productList" + listIndex]) {
    //    tempMasterProductList["productList" + listIndex] = [];
    //  }
    //  if (tempMasterProductList["productList" + listIndex].length < PROVIDERS_PER_ROW) {
    //    tempMasterProductList["productList" + listIndex].push(product);
    //  }
    //  else {
    //    listIndex++;
    //    tempMasterProductList["productList" + listIndex] = [];
    //    tempMasterProductList["productList" + listIndex].push(product);
    //  }
    //}); const [providerCount, setProviderCount] = useState(15);
    let providerCount = 0;
    if (tempAvailableProvidersList.length >= _DEFAULT_PROVIDER_COUNT && categoryName != "All") {
      providerCount = 12;
      setIsShowMoreProvider(true);
    } else {
      providerCount = tempAvailableProvidersList.length;
      setIsShowMoreProvider(false);
    }
    setMasterProviderList(tempAvailableProvidersList.slice(0, providerCount));
  }

  const getBanners = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_BANNER + "?isDesktop=false&languageCode=" +
      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'id'));

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data1 = responseJson[ApiKey._API_DATA_KEY]["banners"];
      let tempMainBannerData = data1.filter(x => x.displayDevice === DisplayDevice._MOBILE_SLIDER);
      let tempSubBannerData = data1.filter(x => x.displayDevice === DisplayDevice._MOBILE_POPOUT);
      setMainBannerData(tempMainBannerData);
      setSubBannerData(tempSubBannerData);
      let data2 = responseJson[ApiKey._API_DATA_KEY]["promoBanners"];
      setPromoBannerData(data2);
      let data3 = responseJson[ApiKey._API_DATA_KEY]["popupBanners"];
      if (data3 != null) {
        _dispatch(setBannerPopupImage(data3));
        _dispatch(setBannerPopupModal(true));
      } else {
        _dispatch(setBannerPopupImage(""));
        _dispatch(setBannerPopupModal(false));
      }
    }
  }

  const getUnclaimCommHistory = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_UNCLAIM_COMMISSION_AMT
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setUnclaimComm(data);
    }
  };

  const getRewardData = async () => {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_CATEGORY_REWARD_RATES + "?all=true"
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let tempObj = {};
      let grandTotalReward = 0;
      let categoryTotal = {};
      data.map((rewardData, index) => {
        if (!tempObj[rewardData["date"]]) {
          tempObj[rewardData["date"]] = { totalAmount: 0, totalAmountClaimable: 0 };
        }
        if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]]) {
          tempObj[rewardData["date"]][rewardData["categoryTitle"]] = { totalAmount: 0, totalAmountClaimable: 0 };
        }
        if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]]) {
          tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]] = { productImage: rewardData["productImage"], rate: rewardData["rewardRate"] };
        }
        tempObj[rewardData["date"]].totalAmount += rewardData["amount"];
        tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
        if (rewardData["claimed"] !== "True") {
          tempObj[rewardData["date"]].totalAmountClaimable += rewardData["amount"];
          tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmountClaimable += rewardData["amount"];
          grandTotalReward += rewardData["amount"];

          if (!categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]] =
            {
              category: rewardData["categoryTitle"],
              totalAmount: rewardData["amount"]
            };
          }
          else if (categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
          }
          else if (categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]] =
            {
              category: rewardData["categoryTitle"],
              totalAmount: rewardData["amount"]
            };
          }
        }

      });
      //let temp = Object.keys(tempObj).sort();
      //setSelectedDate(moment(temp[temp.length - 1]));
      //setRewardData(tempObj);
      setGrandTotalRewards(grandTotalReward);
      //setGameCategoryData(categoryTotal);
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
  }

  const checkMemberShareholderPin = async () => {
    let params = {};
    let responseJson = await ApiEngine.post(ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER, createFormBody(params));
    if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
      setShowCreateShareholderPin(true);
    }
  }

  const submitLoginForm = async (data, e) => {
    _dispatch(setBusy());

    if (data.isKeepedPassword) {
      ls.set("gR97lPRIn797", {
        Do3SwI4cuvqR: data.username,
        gR1IPGBN01dS: data.password,
        PYTLczcty9uH: data.isKeepedPassword,
      });
    } else {
      ls.set("gR97lPRIn797", {
        Do3SwI4cuvqR: "fake",
        gR1IPGBN01dS: "null",
        PYTLczcty9uH: false,
      });
    }
    let tempLangCode = LanguageOption.filter(x => x.languageId == activeLanguageId)[0].code;
    let selectedTheme = themeName === "light" ? 1 : 0;
    setIsLoadingMemberDatas(true);
    await _dispatch(
      performLogin(data.username, data.password, true, tempLangCode, selectedTheme)
    );
    let params = {
      username: data.username,
    };
    var loginFailCountJson = await ApiEngine.post(
      ApiUrl._API_GET_LOGIN_FAILED_COUNT,
      createFormBody(params)
    );
    if (loginFailCountJson[ApiKey._API_SUCCESS_KEY]) {
      let data = loginFailCountJson[ApiKey._API_DATA_KEY];
      setLoginFailCount(data);
    }
    _dispatch(setIdle());
    setLoginModalVisible(false);
  }

  async function submitForgotPasswordForm(data) {
    _dispatch(setBusy());

    let formData = {
      "language": i18n.language,
      "username": data.username
    };
    let formBody = createMultiPartFormBody(formData);
    var responseJson = await ApiEngine.post(
      ApiUrl._API_SEND_RESET_PASSWORD_SMS,
      formBody
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setUsername(data.username);
      //setPhoneNum(responseJson.data)
      setTempPhoneNum(responseJson.data);
      setForgotPasswordSteps(2);
      start();
      setShowKeyboard(true);
    }
    else {
      _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]))
    }
    _dispatch(setIdle());
  }

  async function submitVerifyTAC(data) {
    _dispatch(setBusy());
    if (verifyState.button == 1) {

      let formData = {
        "taccode": enteredPinNumberFinal,
        "username": username
      };
      let formBody = createMultiPartFormBody(formData);
      let TACjson = await ApiEngine.post(
        ApiUrl._API_VERIFY_RESET_PASSWORD_SMS,
        formBody
      );
      if (TACjson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(showResponseMessage(true, TACjson[ApiKey._API_MESSAGE_KEY]));
        setForgotPasswordSteps(3);
        setShowKeyboard(false);
        reset();
      } else {
        _dispatch(showResponseMessage(false, t("TAC_CODE_INCORRECT")));
      }
    } else if (verifyState.button == 2) {

      let formData = {
        "language": i18n.language,
        "username": username
      };
      let formBody = createMultiPartFormBody(formData);
      //data["phoneNumber"] = formattedPhoneNumber;
      data["language"] = i18n.language;
      var responseJson = await ApiEngine.post(
        ApiUrl._API_SEND_RESET_PASSWORD_SMS,
        formBody
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        //setPhoneNum(responseJson.data);
        setTempPhoneNum(responseJson.data);
        setForgotPasswordSteps(2);
        reset();
        start();
      }
    }

    _dispatch(setIdle());
  }

  async function submitRegisterForm(data) {
    _dispatch(setBusy());
    if (!agreeTnC) {
      setShowTncError(true);
    }
    else {
      setShowTncError(false);
    }
    if (!agreeAge18) {
      setShowAgeError(true);
    }
    else {
      setShowAgeError(false);
    }
    if (agreeTnC && agreeAge18) {
      let formattedPhoneNumber = selectedPhonePrefix + data.registerPhoneNumber;
      if (formattedPhoneNumber.length >= 10) {
        let params = {
          phoneNumber: formattedPhoneNumber,
        };
        let responseJson = await ApiEngine.post(
          ApiUrl._API_CHECK_PHONE_NUMBER,
          createFormBody(params)
        );
        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
          _dispatch(showResponseMessage(false, t("PHONE_NUMBER_ALREADY_USED")));
        } else {
          // check if user has changed phonenumber
          if (formattedPhoneNumber == prevPhoneNumber && passTAC === true) {
            setRegisterDetailsSteps(3);
          }
          else {
            setPrevPhoneNumber(formattedPhoneNumber);
            sendTAC(formattedPhoneNumber);
          }
        }
      }
    }
    _dispatch(setIdle());
  }

  function handleResetCode() {
    if (TACtimedOut) {
      sendTAC();
    }
  }

  const adjustFocus = () => {
    if (eleConfirm["current"] !== null) {
      if (
        eleConfirm["current"]["values"][0] == "" ||
        eleConfirm["current"]["values"][0] == undefined
      ) {
        eleConfirm["current"]["elements"][0].focus();
      }
    }
  };

  function onCreatePinKeyPress(key) {
    // 1st: have to know if entering for elePin or eleConfirm
    // 2nd: have to know which index is currently focused for altering the state.value
    // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
    // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

    let currentIndex = 0;
    let pinStr = enteredPinNumber;
    let pinConfirmStr = enteredPinNumberConfirm;
    if (key !== "{bksp}") {
      if (pinNumber === "") {
        currentIndex = pinNumberFocused;
        elePin["current"]["elements"][currentIndex].state.value = key;
        pinStr += key;
        setEnteredPinNumber(pinStr);
        if (currentIndex >= 0 && currentIndex < 3) {
          currentIndex++;
          setPinNumberFocused(currentIndex);
          elePin["current"]["elements"][currentIndex].focus();
        } else {
          setPinNumber(pinStr);
        }
      } else {
        currentIndex = pinNumberConfirmFocused;
        eleConfirm["current"]["elements"][currentIndex].state.value = key;
        pinConfirmStr += key;
        setEnteredPinNumberConfirm(pinConfirmStr);
        currentIndex++;
        setPinNumberConfirmFocused(currentIndex);
        if (currentIndex <= 3) {
          eleConfirm["current"]["elements"][currentIndex].focus();
        } else {
          setPinNumberConfirm(pinConfirmStr);
          submitPin(pinNumber, pinConfirmStr);
        }
      }
    } else {
      elePin["current"].clear();
      eleConfirm["current"].clear();
      setPinNumber("");
      setEnteredPinNumber("");
      setPinNumberFocused(currentIndex);
      setPinNumberConfirm("");
      setEnteredPinNumberConfirm("");
      setPinNumberConfirmFocused(currentIndex);
      elePin["current"]["elements"][currentIndex].focus();
    }
  }

  async function submitPin(pin, conf) {
    _dispatch(setBusy());
    let params = {
      pinNumber: pin,
      confirmPinNumber: conf,
    };
    let responseJson = await ApiEngine.post(
      ApiUrl._API_SET_SHAREHOLDER_PIN_NUMBER,
      createFormBody(params)
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setShowCreateShareholderPin(false);
      setInvalidPin(false);
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    } else {
      setErrorMessage(responseJson[ApiKey._API_MESSAGE_KEY]);
      setInvalidPin(true);
      elePin["current"].clear();
      eleConfirm["current"].clear();
      setPinNumber("");
      setEnteredPinNumber("");
      setPinNumberFocused(0);
      setPinNumberConfirm("");
      setEnteredPinNumberConfirm("");
      setPinNumberConfirmFocused(0);
      elePin["current"].focus();
    }
    _dispatch(setIdle());
  }

  async function sendTAC(phoneNumber) {
    setPinNumberFinal("");
    setEnteredPinNumberFinal("");
    setPinNumberFinalFocused(0);
    setPassTAC(false);
    clearTimeout(myTimer);
    _dispatch(setBusy());
    let params = {
      "phoneNumber": (phoneNumber == null ? selectedPhonePrefix + registerPhoneNumber : phoneNumber),
      "referralCode": referralCode,
      "languageCode": activeLanguage,
      "countdown": (_TAC_VALID_TIMER / _SECONDS_IN_MS),
      "TACExist": !TACtimedOut
    };

    let responseJson = await ApiEngine.post(ApiUrl._API_SEND_TAC_CODE, createFormBody(params));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setTACtimedOut(false);
      //if (countdownPeriod == 0) {
      //  setCountdownPeriod((_TAC_VALID_TIMER / _SECONDS_IN_MS));
      //}
      setCountdownPeriod((_TAC_VALID_TIMER / _SECONDS_IN_MS));
      setShowPhoneText(true);
      setRegisterDetailsSteps(2);
    }
    else {
      _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
    }
    _dispatch(setIdle());
  }

  async function submitTransactionPin(data) {
    _dispatch(setBusy());

    let TACparams = {
      "TAC": pinNumberFinal,
      "phoneNumber": selectedPhonePrefix + registerPhoneNumber,
      "referralCode": referralCode
    };
    let TACjson = await ApiEngine.post(ApiUrl._API_VERIFY_TAC_CODE, createFormBody(TACparams));
    if (TACjson[ApiKey._API_SUCCESS_KEY]) {
      setPassTAC(true);
      setRegisterDetailsSteps(3);
    }
    else {
      eleFinalPin["current"].clear();
      setPinNumberFinal("");
      setEnteredPinNumberFinal("");
      setPinNumberFinalFocused(0);
      eleFinalPin['current']['elements'][0].focus();
      _dispatch(showResponseMessage(false, TACjson[ApiKey._API_MESSAGE_KEY]));
    }

    _dispatch(setIdle());
  }

  async function submitGeneratePassword(data) {
    _dispatch(setBusy());
    let params = {
      "username": registerUsername,
      "password": data.registerPassword,
      "userFullName": registerFullName,
      "phoneNumber": selectedPhonePrefix + registerPhoneNumber,
      //"email": data.email,
      "referralCode": referralCode,
      "isMemberOperate": true,
      "languageSetting": activeLanguageId
    };
    let responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_MEMBER, createFormBody(params));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      await _dispatch(performLogin(registerUsername, data.registerPassword));
      await registerSuccess();
      await _dispatch(setIdle());
    } else {
      _dispatch(showMessage({
        type: AlertTypes._ERROR,
        content: t(responseJson[ApiKey._API_MESSAGE_KEY])
      }));
      _dispatch(setIdle());
    }

  }

  async function checkUsername(username) {
    // check if username is taken
    let avail = true;
    let responseJson = await ApiEngine.get(ApiUrl._API_CHECK_USERNAME + "?username=" + username + "&code=" + referralCode);
    avail = responseJson[ApiKey._API_SUCCESS_KEY];
    return avail;
  }

    async function updateUserWalletBalance(spinButtonClicked = false) {
        setRefreshWalletIsSpining(spinButtonClicked);
        let walletResponse = await ApiEngine.get(ApiUrl._API_GET_MEMBER_WALLET_BALANCE);
        if (walletResponse[ApiKey._API_SUCCESS_KEY]) {
            let data = walletResponse[ApiKey._API_DATA_KEY];
            setCurrentWalletBalance(data["primaryCredit"]);
            if (spinButtonClicked && !refreshBalanceSpin) {
                setRefreshWalletIsSpining(false);
                setRefreshBalanceSpin(true);
                setTimeout(() => setRefreshBalanceSpin(false), [5000]);
            }

            if (data["count"] === false) {
                setGetBalanceDelay(30000);
            }
            else {
                setGetBalanceDelay(5000);
            }
        }
    }

  function setGetBalanceDelay(mmTimer) {
    walletBalanceInterval.current = setTimeout(() => updateUserWalletBalance(), mmTimer);
  }

  const validateUsername = (e) => {
    var regexp = /^[\S]*^[^ \[\]`~\/!@#$%^&*()"':;?|{}=+_<>,.\\-]*$/g;
    if (e.nativeEvent.data === null || regexp.test(e.nativeEvent.data)) {
      setUsername(e.target.value);
    }
  }

  async function submitNewPassword(data) {
    _dispatch(setBusy());
    let formData = {
      "tacCode": enteredPinNumberFinal,
      "newpassword": data.newPassword,
      "confpassword": data.confpassword,
      "username": username
    };
    let formBody = createMultiPartFormBody(formData);

    var responseJson = await ApiEngine.post(
      ApiUrl._API_MEMBER_RESET_PASSWORD_BEFORE_LOGIN,
      formBody
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      //setIsUpdatePassword(false)
      //setIsPasswordUpdated(true)
      setForgotPasswordModalVisible(false);
      setSuccessPasswordModalVisible(true);
      setForgotPasswordSteps(1);
    } else {
      _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
    }
    _dispatch(setIdle());
  }

  function onKeyPress(key) {
    let currentIndex = 0;
    let pinStr = enteredPinNumberFinal;
    if (key !== "{bksp}") {
      if (key === "{enter}") {
        if (pinEntered) {
          setShowPinEntry(false);
        }
      }
      if (pinNumberFinal === "") {
        currentIndex = pinNumberFinalFocused;
        eleFinalPin['current']['elements'][currentIndex].state.value = key;
        pinStr += key;
        setEnteredPinNumberFinal(pinStr);
        if (currentIndex !== 5) {
          currentIndex++;
          setPinNumberFinalFocused(currentIndex);
          eleFinalPin['current']['elements'][currentIndex].focus();
        }
        else {
          setPinNumberFinal(pinStr);
          setPinEntered(true);
        }
      }
    }
    else {
      eleFinalPin["current"].clear();
      setPinNumberFinal("");
      setEnteredPinNumberFinal("");
      setPinNumberFinalFocused(currentIndex);
      eleFinalPin['current']['elements'][currentIndex].focus();
    }
  }

  function onVerifyPinKeyPress(key) {
    let currentIndex = 0;
    let pinStr = enteredPinNumberFinal;
    if (key !== "{bksp}") {
      if (pinNumberFinal === "") {
        currentIndex = pinNumberFinalFocused;
        eleFinalPin["current"]["elements"][currentIndex].state.value = key;
        pinStr += key;
        setEnteredPinNumberFinal(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setPinNumberFinalFocused(currentIndex);
          eleFinalPin["current"]["elements"][currentIndex].focus();
        } else {
          verifyPin(pinStr);
        }
      }
    } else {
      eleFinalPin["current"].clear();
      setPinNumberFinal("");
      setEnteredPinNumberFinal("");
      setPinNumberFinalFocused(currentIndex);
      eleFinalPin["current"]["elements"][currentIndex].focus();
    }
  }

  const handleCategoryClick = (categoryName) => {
    let tempCatName = categoryName;
    if (categoryActive === categoryName || categoryName === "All") {
      tempCatName = "All";
    }
    setActiveProvider(0);
    setActiveProviderName("");
    setCategoryActive(tempCatName);
    sortProviders(tempCatName);
    if (categoryName === "Connect") {
      let connectDetails = allAvailableProvidersList.filter((x) => {
        if (x.productName === "93Connect") return x.game;
      })
      let gameDetails = connectDetails.map((item) => item.game);
      //startGame(gameDetails[0]);
      _dispatch(
        startGame(gameDetails[0])
      )
    }
  }

  //const getMemberSpecialRollover = async () => {
  //  let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_IS_SPECIAL_ROLLOVER);
  //  let data = "";

  //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //    data = responseJson[ApiKey._API_DATA_KEY];
  //    //setIsSpecialRollover(data);
  //    _dispatch(setIsSpecialRollover(data));
  //    if (data === false) {
  //      clearInterval(rolloverFlagInterval.current);
  //      clearInterval(rolloverInterval.current);
  //      setCurrentRolloverBalance(0);
  //      //setPlayableCategory([]);
  //      //setRolloverTarget(0);
  //      _dispatch(setPlayableCategory([]));
  //      _dispatch(setRolloverTarget(0));
  //    }
  //  }
  //}

  //const getBonusDepositProductCategory = async () => {
  //  let responseJson = await ApiEngine.get(ApiUrl._API_GET_BONUS_DEPOSIT_PRODUCT_CATEGORY);

  //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //    let data = responseJson[ApiKey._API_DATA_KEY];
  //    let productCategoryArr = [];

  //    if (data.includes(",")) {
  //      productCategoryArr = responseJson[ApiKey._API_DATA_KEY].split(",");
  //    }
  //    else {
  //      productCategoryArr.push(data);
  //    }

  //    let tempArr = [];
  //    [...productCategory].forEach((x) => {
  //      if (productCategoryArr.includes(x.value)) {
  //        tempArr.push(x.originalTitle);
  //      }
  //    })

  //    //setPlayableCategory(tempArr);
  //    _dispatch(setPlayableCategory(tempArr));
  //  }
  //}

  //const getMemberCurrentRolloverBalance = async () => {
  //  let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_CURRENT_ROLLOVER_BALANCE);

  //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //    let data = responseJson[ApiKey._API_DATA_KEY];
  //    setCurrentRolloverBalance(data);
  //  }
  //  setIsLoadingRolloverDatas(false);
  //}

  //const startGameProvider = async (productGame) => {

  //  if (isLoggedIn) {
  //    if (!isEmpty(productGame)) {
  //      const { id, isApp, isNewWindow, isSeamless, gameName, hasOwnLobby, productCategory } = productGame;

  //      if (isSpecialRollover) {
  //        let productCategoryListArr = [...playableCategory];
  //        if (productCategoryListArr.includes(productCategory.originalTitle)) {
  //          if (hasOwnLobby !== true) {
  //            if (isSeamless == false) {
  //              window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
  //            } else {
  //              if (!window.ReactNativeWebView) {
  //                window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
  //              }
  //              else {
  //                if (isApp) {
  //                  if (gameName !== "LionKing") {
  //                    window.ReactNativeWebView.postMessage(
  //                      JSON.stringify({
  //                        action: "deeplink",
  //                        url: gameAppUrl,
  //                        deeplink: gameAppDeepLink
  //                          .replace("[username]", mpData["username"])
  //                          .replace("[password]", mpData["password"]),
  //                        packageKeyword: gameAppPackageNameKeyword
  //                      })
  //                    );
  //                  }
  //                }
  //                if (isNewWindow) {
  //                  window.ReactNativeWebView.postMessage(
  //                    JSON.stringify({
  //                      url:
  //                        window.location.origin +
  //                        ApiUrl._API_START_GAME +
  //                        "?gameId=" +
  //                        id
  //                    })
  //                  );
  //                } else {
  //                  if (gameName === "LionKing" || gameName === "918Kiss" || gameName === "Mega888") {
  //                    window.ReactNativeWebView.postMessage(
  //                      JSON.stringify({
  //                        packageKeyword: gameAppPackageNameKeyword
  //                      })
  //                    );
  //                  }
  //                  else {
  //                    window.ReactNativeWebView.postMessage(
  //                      JSON.stringify({
  //                        url:
  //                          window.location.origin +
  //                          WebUrl._URL_GAME_PAGE +
  //                          "?gameId=" +
  //                          id
  //                      })
  //                    );
  //                  }
  //                }
  //              }
  //            }
  //          }
  //          else {
  //            //if (isSeamless == false) {
  //            //  window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
  //            //} else {
  //            //  _history.push(
  //            //    WebUrl._URL_GAME_LOBBY +
  //            //    "?name=" +
  //            //    gameName +
  //            //    "&id=" +
  //            //    id
  //            //    //  +
  //            //    // "&languageId=" +
  //            //    // memberData["languageSetting"]
  //            //  );
  //            //}
  //            if (window.ReactNativeWebView) {
  //              window.ReactNativeWebView.postMessage(
  //                JSON.stringify({
  //                  url:
  //                    window.location.origin +
  //                    ApiUrl._API_START_GAME +
  //                    "?gameId=" +
  //                    id +
  //                    "&isApp=" + (isApp ? 1 : 0) +
  //                    "&device=m"
  //                })
  //              );
  //            }
  //            else {
  //              window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
  //            }
  //          }
  //        }
  //        else {
  //          _dispatch(
  //            showMessage({
  //              type: AlertTypes._INFO,
  //              content: t("ROLLOVER_EXIST"),
  //              onConfirm: () => {
  //              },
  //            })
  //          );
  //        }
  //      }
  //      else {
  //        if (hasOwnLobby !== true) {
  //          if (isSeamless == false) {
  //            window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
  //          } else {
  //            if (!window.ReactNativeWebView) {
  //              window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
  //            }
  //            else {
  //              if (isApp) {
  //                if (gameName !== "LionKing") {
  //                  window.ReactNativeWebView.postMessage(
  //                    JSON.stringify({
  //                      action: "deeplink",
  //                      url: gameAppUrl,
  //                      deeplink: gameAppDeepLink
  //                        .replace("[username]", mpData["username"])
  //                        .replace("[password]", mpData["password"]),
  //                      packageKeyword: gameAppPackageNameKeyword
  //                    })
  //                  );
  //                }
  //              }
  //              if (isNewWindow) {
  //                window.ReactNativeWebView.postMessage(
  //                  JSON.stringify({
  //                    url:
  //                      window.location.origin +
  //                      ApiUrl._API_START_GAME +
  //                      "?gameId=" +
  //                      id
  //                  })
  //                );
  //              } else {
  //                if (gameName === "LionKing" || gameName === "918Kiss" || gameName === "Mega888") {
  //                  window.ReactNativeWebView.postMessage(
  //                    JSON.stringify({
  //                      packageKeyword: gameAppPackageNameKeyword
  //                    })
  //                  );
  //                }
  //                else {
  //                  window.ReactNativeWebView.postMessage(
  //                    JSON.stringify({
  //                      url:
  //                        window.location.origin +
  //                        WebUrl._URL_GAME_PAGE +
  //                        "?gameId=" +
  //                        id
  //                    })
  //                  );
  //                }
  //              }
  //            }
  //          }
  //        }
  //        else {
  //          //if (isSeamless == false) {
  //          //  window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
  //          //} else {
  //          //  _history.push(
  //          //    WebUrl._URL_GAME_LOBBY +
  //          //    "?name=" +
  //          //    gameName +
  //          //    "&id=" +
  //          //    id
  //          //    //  +
  //          //    // "&languageId=" +
  //          //    // memberData["languageSetting"]
  //          //  );
  //          //}
  //          if (window.ReactNativeWebView) {
  //            window.ReactNativeWebView.postMessage(
  //              JSON.stringify({
  //                url:
  //                  window.location.origin +
  //                  ApiUrl._API_START_GAME +
  //                  "?gameId=" +
  //                  id +
  //                  "&isApp=" + (isApp ? 1 : 0) +
  //                  "&device=m"
  //              })
  //            );
  //          }
  //          else {
  //            window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
  //          }
  //        }
  //      }
  //    }
  //  }
  //  else {
  //    _dispatch(
  //      showMessage({
  //        type: AlertTypes._INFO,
  //        content: t("PLEASE_LOGIN_FIRST"),
  //        onConfirm: () => {
  //        },
  //      })
  //    );
  //  }
  //}

  //const startGame = async (productGame) => {
  //  if (isLoggedIn) {
  //    if (!isEmpty(productGame)) {
  //      const { id, isApp, isNewWindow, isSeamless, gameName, hasOwnLobby, categoryTitle } = productGame;

  //      if (isSpecialRollover) {
  //        let productCategoryListArr = [...playableCategory];
  //        if (productCategoryListArr.includes(categoryTitle)) {
  //          if (hasOwnLobby !== true) {
  //            if (isSeamless == false) {
  //              window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
  //            } else {
  //              if (!window.ReactNativeWebView) {
  //                window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
  //              }
  //              else {
  //                if (isApp) {
  //                  if (gameName !== "LionKing") {
  //                    window.ReactNativeWebView.postMessage(
  //                      JSON.stringify({
  //                        action: "deeplink",
  //                        url: gameAppUrl,
  //                        deeplink: gameAppDeepLink
  //                          .replace("[username]", mpData["username"])
  //                          .replace("[password]", mpData["password"]),
  //                        packageKeyword: gameAppPackageNameKeyword
  //                      })
  //                    );
  //                  }
  //                }
  //                if (isNewWindow) {
  //                  window.ReactNativeWebView.postMessage(
  //                    JSON.stringify({
  //                      url:
  //                        window.location.origin +
  //                        ApiUrl._API_START_GAME +
  //                        "?gameId=" +
  //                        id
  //                    })
  //                  );
  //                } else {
  //                  if (gameName === "LionKing" || gameName === "918Kiss" || gameName === "Mega888") {
  //                    window.ReactNativeWebView.postMessage(
  //                      JSON.stringify({
  //                        packageKeyword: gameAppPackageNameKeyword
  //                      })
  //                    );
  //                  }
  //                  else {
  //                    window.ReactNativeWebView.postMessage(
  //                      JSON.stringify({
  //                        url:
  //                          window.location.origin +
  //                          WebUrl._URL_GAME_PAGE +
  //                          "?gameId=" +
  //                          id
  //                      })
  //                    );
  //                  }
  //                }
  //              }
  //            }
  //          }
  //          else {
  //            //if (isSeamless == false) {
  //            //  window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
  //            //} else {
  //            //  _history.push(
  //            //    WebUrl._URL_GAME_LOBBY +
  //            //    "?name=" +
  //            //    gameName +
  //            //    "&id=" +
  //            //    id
  //            //    //  +
  //            //    // "&languageId=" +
  //            //    // memberData["languageSetting"]
  //            //  );
  //            //}
  //            if (window.ReactNativeWebView) {
  //              window.ReactNativeWebView.postMessage(
  //                JSON.stringify({
  //                  url:
  //                    window.location.origin +
  //                    ApiUrl._API_START_GAME +
  //                    "?gameId=" +
  //                    id +
  //                    "&isApp=" + (isApp ? 1 : 0) +
  //                    "&device=m"
  //                })
  //              );
  //            }
  //            else {
  //              window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
  //            }
  //          }
  //        }
  //        else {
  //          _dispatch(
  //            showMessage({
  //              type: AlertTypes._INFO,
  //              content: t("ROLLOVER_EXIST"),
  //              onConfirm: () => {
  //              },
  //            })
  //          );
  //        }
  //      }
  //      else {
  //        if (hasOwnLobby !== true) {
  //          if (isSeamless == false) {
  //            window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
  //          } else {
  //            if (!window.ReactNativeWebView) {
  //              window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
  //            }
  //            else {
  //              if (isApp) {
  //                if (gameName !== "LionKing") {
  //                  window.ReactNativeWebView.postMessage(
  //                    JSON.stringify({
  //                      action: "deeplink",
  //                      url: gameAppUrl,
  //                      deeplink: gameAppDeepLink
  //                        .replace("[username]", mpData["username"])
  //                        .replace("[password]", mpData["password"]),
  //                      packageKeyword: gameAppPackageNameKeyword
  //                    })
  //                  );
  //                }
  //              }
  //              if (isNewWindow) {
  //                window.ReactNativeWebView.postMessage(
  //                  JSON.stringify({
  //                    url:
  //                      window.location.origin +
  //                      ApiUrl._API_START_GAME +
  //                      "?gameId=" +
  //                      id
  //                  })
  //                );
  //              } else {
  //                if (gameName === "LionKing" || gameName === "918Kiss" || gameName === "Mega888") {
  //                  window.ReactNativeWebView.postMessage(
  //                    JSON.stringify({
  //                      packageKeyword: gameAppPackageNameKeyword
  //                    })
  //                  );
  //                }
  //                else {
  //                  window.ReactNativeWebView.postMessage(
  //                    JSON.stringify({
  //                      url:
  //                        window.location.origin +
  //                        WebUrl._URL_GAME_PAGE +
  //                        "?gameId=" +
  //                        id
  //                    })
  //                  );
  //                }
  //              }
  //            }
  //          }
  //        }
  //        else {
  //          //if (isSeamless == false) {
  //          //  window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
  //          //} else {
  //          //  _history.push(
  //          //    WebUrl._URL_GAME_LOBBY +
  //          //    "?name=" +
  //          //    gameName +
  //          //    "&id=" +
  //          //    id
  //          //    //  +
  //          //    // "&languageId=" +
  //          //    // memberData["languageSetting"]
  //          //  );
  //          //}
  //          if (window.ReactNativeWebView) {
  //            window.ReactNativeWebView.postMessage(
  //              JSON.stringify({
  //                url:
  //                  window.location.origin +
  //                  ApiUrl._API_START_GAME +
  //                  "?gameId=" +
  //                  id +
  //                  "&isApp=" + (isApp ? 1 : 0) +
  //                  "&device=m"
  //              })
  //            );
  //          }
  //          else {
  //            window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
  //          }
  //        }
  //      }
  //    }
  //  }
  //  else {
  //    _dispatch(
  //      showMessage({
  //        type: AlertTypes._INFO,
  //        content: t("PLEASE_LOGIN_FIRST"),
  //        onConfirm: () => {
  //        },
  //      })
  //    );
  //  }
  //}

  async function startGameLionKing(gameArr) {
    const mpJsonLionKing = await ApiEngine(
      ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS_LION_KING +
      "?masterProductId=" +
      gameArr[0].masterProductId + "&gameId=" + gameArr[0].gameId + "&device=m"
    );

    if (mpJsonLionKing[ApiKey._API_SUCCESS_KEY]) {
      const data = mpJsonLionKing[ApiKey._API_DATA_KEY];
      const deeplink = data.split("jqk://");

      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: "deeplink",
          url: gameArr[0].appUrl,
          deeplink: gameArr[0].appDeepLink
            .replace("[signature]", deeplink[1]),
          packageKeyword: gameArr[0].packageKeyword,
        })
      );
    }
  }

  async function startGame568SportsBook(matchId) {
    if (isLoggedIn) {
      _dispatch(setBusy());
      if (isSportEventUM || SBOSportsGameId === 0) {
        _dispatch(
          showMessage({
            type: AlertTypes._INFO,
            content: t("GAME_UNDER_MAINTENANCE"),
            onConfirm: () => {
            },
          })
        );
      } else {
        if (isSpecialRollover) {
          let productCategoryListArr = [...playableCategory];
          if (productCategoryListArr.includes("Sports")) {
            window.open(ApiUrl._API_START_GAME + "?gameId=" + SBOSportsGameId + "&isApp=0&device=m&matchId=" + matchId);
            //const result = await ApiEngine(
            //  ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS_SPORTS_BOOK +
            //  "?matchId=" + matchId + "&device=m"
            //);

            //if (result[ApiKey._API_SUCCESS_KEY]) {
            //  const data = result[ApiKey._API_DATA_KEY];


            //  if (window.ReactNativeWebView) {
            //    window.ReactNativeWebView.postMessage(
            //      JSON.stringify({
            //        url: data,
            //      })
            //    );
            //  } else {
            //    if (isSafari == true) {
            //      setSportEventUrl(data);
            //      setIsSportEvent(true);
            //    } else {
            //      window.open(data);
            //    }
            //  }
            //}
          }
          else {
            _dispatch(
              showMessage({
                type: AlertTypes._INFO,
                content: t("ROLLOVER_EXIST"),
                onConfirm: () => {
                },
              })
            );
          }
        }
        else {
          window.open(ApiUrl._API_START_GAME + "?gameId=" + SBOSportsGameId + "&isApp=0&device=m&matchId=" + matchId);
          //const result = await ApiEngine(
          //  ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS_SPORTS_BOOK +
          //  "?matchId=" + matchId + "&device=m"
          //);

          //if (result[ApiKey._API_SUCCESS_KEY]) {
          //  const data = result[ApiKey._API_DATA_KEY];


        }
      }
      _dispatch(setIdle());
    }
    else {
      _dispatch(
        showMessage({
          type: AlertTypes._INFO,
          content: t("PLEASE_LOGIN_FIRST"),
          onConfirm: () => {
          },
        })
      );
    }
  }

  const handleProviderClick = async (provider) => {
    if (!provider.isUM) {
      if (provider != null) {
        let tempProviderName = provider.productName;
        setActiveProviderName(tempProviderName);
      }

      //if (!provider.hasOwnLobby) { setActiveSoloGame(provider.game); }
      if (!provider.isSeamless) {
        if (!provider.hasOwnLobby) {
          if (isLoggedIn) {
            _dispatch(setBusy());
            _dispatch(startGame(provider.productGame));
            _dispatch(setIdle());
          } else {
            _dispatch(
              showMessage({
                type: AlertTypes._INFO,
                content: t("PLEASE_LOGIN_FIRST"),
                onConfirm: () => {
                },
              })
            );
          }
        } else {
          //_history.push({
          //  pathname: WebUrl._URL_PROVIDER_GAME_LOBBY,
          //  search: "?name=" + provider.productName
          //});
          _history.push({
            pathname: WebUrl._URL_PROVIDER_LOBBY.replace(":category", provider.categoryName).replace(":provider?", provider.productName),
            state: {
              "providerList": allAvailableProvidersList
            }
          })
        }
      }
      else {
        if (!provider.hasOwnLobby) {
          if (isLoggedIn) {
            //startGameProvider(provider.game);
            _dispatch(startGame(provider.productGame));
          }
          else {
            _dispatch(
              showMessage({
                type: AlertTypes._INFO,
                content: t("PLEASE_LOGIN_FIRST"),
                onConfirm: () => {
                },
              })
            );
          }
        }
        else {
          //_history.push({
          //  pathname: WebUrl._URL_PROVIDER_GAME_LOBBY,
          //  search: "?name=" + provider.productName
          //});
          _history.push({
            pathname: WebUrl._URL_PROVIDER_LOBBY.replace(":category", provider.categoryName).replace(":provider?", provider.productName),
            state: {
              "providerList": allAvailableProvidersList
            }
          })
        }
      }
    }
  }

  async function displayTnC() {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_CONTENT_LANGUAGE + "?title=TnC&languageCode=" + activeLanguage);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setTncContent(data);
    }
    setShowTnC(true);
    return false;
  }

  function registerDetailsBack() {
    setRegisterDetailsModalVisible(false);
    setRegisterModalVisible(true);
    setRegisterFullName("");
    setRegisterUsername("");
    setRegisterPhoneNumber("");
    setSelectedPhonePrefix("66");
    setPassTAC(false);
    setAgreeAge18(false);
    setAgreeTnC(false);
  }

  function registerSuccess() {
    setRegisterDetailsModalVisible(false);
    setRegisterDetailsSteps(1);
    setRegisterFullName("");
    setRegisterUsername("");
    setRegisterPhoneNumber("");
    setSelectedPhonePrefix("66");
    setPassTAC(false);
    setAgreeAge18(false);
    setAgreeTnC(false);
    setSuccessRegisterModalVisible(true);
  }
  function goToHome() {
    setSuccessPasswordModalVisible(false);
    setSuccessRegisterModalVisible(false);
    setAgreeAge18(false);
    setAgreeTnC(false);
    setRegisterModalVisible(false);
    return true;
  }

  function agreeButton() {
    setAgreeTnC(true);
    setShowTnC(false)
    return true;
  }

  function cancelButton() {
    setAgreeTnC(false);
    setShowTnC(false)
    return true;
  }

  //const submitTransferForm = async (data, e) => {
  //  let conversionRateAmount = numberWithCurrencyFormat(
  //    parseFloat(watchPopupTransfer("amount")) * providerWalletData.productConversionRate,
  //    2,
  //    true
  //  );

  //  _dispatch(setBusy());
  //  let params = {
  //    fromWallet: _MAIN_WALLET_ID,
  //    toWallet: providerWalletData.productId,
  //    amount: data.amount,
  //    conversionRateAmount:
  //      activeProviderName == '918Kiss' || activeProviderName == "D2 Poker" || activeProviderName == "Pussy888" || activeProviderName == "Mega888" ?
  //        conversionRateAmount : 0,
  //  };

  //  let responseJson = await ApiEngine.post(
  //    ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION,
  //    createFormBody(params)
  //  );

  //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //    await updateWalletBalance(
  //      parseFloat(responseJson[ApiKey._API_DATA_KEY].amount).toFixed(2),
  //      providerWalletData.productId
  //    );
  //    setPaidAmount(responseJson[ApiKey._API_DATA_KEY].amount);
  //    setTransferDate(moment().format("YYYY-MM-DD HH:mm:ss"));
  //    setIsTransferSuccess(true);
  //    setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY].transactionId);

  //    e.target.reset();
  //  } else {
  //    _dispatch(
  //      showResponseMessage(
  //        responseJson[ApiKey._API_SUCCESS_KEY],
  //        responseJson[ApiKey._API_MESSAGE_KEY]
  //      )
  //    );
  //  }
  //  _dispatch(setIdle());
  //}

  //async function updateWalletBalance() {
  //  var responseJson = await ApiEngine.get(
  //    ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE + "?masterProductId=" +
  //    providerWalletData.productId
  //  );
  //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //    let walletData = responseJson[ApiKey._API_DATA_KEY];
  //    setAllWalletData(walletData);
  //    let mainWalletData = walletData.filter(x => x.productId === _MAIN_WALLET_ID)[0];
  //    setCurrentWalletBalance(parseFloat(mainWalletData.balance));
  //    let tempProviderWalletData = walletData.filter(x => x.productId === providerWalletData.productId)[0];
  //    setProviderWalletData(tempProviderWalletData);
  //  }
  //}

  //function copyText(textType) {
  //  setShowCopyAlert(false);
  //  clearTimeout(timeoutRef);
  //  if (textType === "username") {
  //    usernameRef.current.select();
  //  } else if (textType === "password") {
  //    passwordRef.current.select();
  //  }
  //  document.execCommand("copy");
  //  setAlertMessage(t(textType.toUpperCase()) + " " + t("COPY_SUCCESS"));
  //  setShowCopyAlert(true);
  //  timeoutRef = setTimeout(() => setShowCopyAlert(false), 3000);
  //}

  //async function resetProductPassword() {
  //  let apiUrl =
  //    ApiUrl._API_CHANGE_PRODUCT_PASSWORD +
  //    "?masterProductId=" +
  //    providerWalletData.productId;
  //  let responseJson = await ApiEngine.get(apiUrl);
  //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //    let data = responseJson[ApiKey._API_DATA_KEY];
  //    let tempProviderWalletData = providerWalletData;
  //    tempProviderWalletData.password = data.password;
  //    setProviderWalletData({ ...tempProviderWalletData });
  //  }
  //}

  async function getUpline(code) {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_UPLINE_BY_REFERRAL_CODE + "?code=" + code);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setReferralCode(code);
      setUplineDetail(responseJson[ApiKey._API_DATA_KEY]);
        setRegisterDetailsModalVisible(true);
        let apiUrl1 = ApiUrl._API_GET_WHATSAPP_PHONE + "?type=ChatSpherePhoneNumber";
      let settingJson = await ApiEngine.get(apiUrl1);

      if (settingJson[ApiKey._API_SUCCESS_KEY]) {
        let data1 = settingJson[ApiKey._API_DATA_KEY];
        setRegisterWhatsAppUrl("https://wa.me/" + data1 + _APPEND_REGISTER_WHATSAPP_URL + code);
      }
    }
    else {
      //responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_DEFAULT_REFERRAL_CODE);
      //if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      //  var receivedReferralCode = responseJson[ApiKey._API_DATA_KEY];
      //  if (!stringIsNullOrEmpty(receivedReferralCode)) {
      //    setReferralCode(receivedReferralCode);
      //    getUpline(receivedReferralCode);
      //  }
      //  else {
      //    _history.replace(WebUrl._URL_MAIN);
      //  }
      //}
      //else {
      _dispatch(showMessage({
        type: AlertTypes._ERROR,
        content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
        onConfirm: () => _history.replace(WebUrl._URL_MAIN)
      }));
      //}
    }
    _dispatch(setIdle());

  }

  async function getMarqueeTagContent() {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MARQUEE_TAG_CONTENT
      + "?MarqueeTagId=2"
      + "&LanguageId=" + activeLanguageId
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      responseJson[ApiKey._API_DATA_KEY].map((option) => {
        setContent(option["content"]);
      });
    }
  }

  async function sendPinCode() {
    if (countdownPeriod < 1) {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_CHECK_IF_PHONE_VERIFIED
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        var hpNum = responseJson[ApiKey._API_DATA_KEY];
        let params = {
          phoneNumber: hpNum,
          isShareholder: "true",
        };
        let smsJson = await ApiEngine.post(
          ApiUrl._API_SEND_PIN_CODE,
          createFormBody(params)
        );
        if (smsJson[ApiKey._API_SUCCESS_KEY]) {
          setForgetPin(false);
          setSmsSent(true);
          setCountdownPeriod(_COUNTDOWN_PERIOD);
        }
      } else {
        _dispatch(
          showResponseMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            responseJson[ApiKey._API_MESSAGE_KEY]
          )
        );
      }
    } else {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT]);
    }
  }

  const verifyPin = async (pin) => {
    let params = {
      pinNumber: pin,
    };
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
      createFormBody(params)
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setInvalidPin(false);
      localStorage.setItem("6FAZhVi1QvBGsKaUl7WPhVTwRPf1HUhF", "wdAvBAPKCPB1TvXPsfPSda8PUUdEGaHg");
      _history.push(
        WebUrl._URL_SHAREHOLDER +
        "?GAzobE73Vse4nr8WpufA=" +
        _userData.hideSummary
      );
    } else {
      localStorage.setItem("6FAZhVi1QvBGsKaUl7WPhVTwRPf1HUhF", "2vr5EqInInUuJj9LS0nK7e3hIfjKAGJv");
      setInvalidPin(true);
      setErrorMessage(responseJson[ApiKey._API_MESSAGE_KEY]);
      eleFinalPin["current"].clear();
      setPinNumberFinal("");
      setEnteredPinNumberFinal("");
      setPinNumberFinalFocused(0);
      eleFinalPin["current"]["elements"][0].focus();
    }
    // }
  };

  const handleCountryOnClick = async (countryItem, selectedLanguage) => {
    if (typeof (selectedLanguage) !== "undefined") {
      changeLanguage(selectedLanguage);
    }
    if (countryItem.url !== window.location.origin) {
      setIsChangeCountry(true);
      await _dispatch(performLogout());

      let redirectUrl = countryItem.url + "?languageCode=" + (typeof (selectedLanguage) !== "undefined" ? selectedLanguage : "th");
      if (window.ReactNativeWebView) {
        const splitUrl = redirectUrl.split(".");

        let mobileRedirectUrl = "";

        if (splitUrl[1].match("live/")) {
          if (splitUrl[0].match("https://m") === null) {
            mobileRedirectUrl = redirectUrl.replace("https://", 'https://m.');
          }
          else {
            mobileRedirectUrl = redirectUrl;
          }
        }
        else {
          mobileRedirectUrl = redirectUrl;
        }

        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            action: "changeCountry",
            countryUrl: mobileRedirectUrl,
          })
        );
      }
      window.location.assign(redirectUrl);
    }
    setShowNavCountry(false);
  }

  const changeLanguage = async (selectedLanguage) => {
    i18n.changeLanguage(selectedLanguage);
    _dispatch(saveLanguageId(selectedLanguage));
    let tempLangId = LanguageOption.filter(x => x.code == selectedLanguage)[0].languageId;
    setActiveLanguage(selectedLanguage);
    setActiveLanguageId(tempLangId);
    if (isLoggedIn) {
      let params =
      {
        "languageSetting": selectedLanguage
      };
      let langJson = await ApiEngine.post(ApiUrl._API_UPDATE_MEMBER_LANGUAGE, createFormBody(params));
      if (!langJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(showMessage(false, t("ERROR_SAVING_LANGUAGE_TO_MEMBER")));
      }

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            action: "language",
            language: selectedLanguage,
          })
        );
      }
    }
  };

  const toggleHiddenForgot = () => {
    setNewForgotLoginPasswordVisible(!newForgotLoginPasswordVisible);
    if (isHiddenForgot === closedEye) {
      setIsHiddenForgot(openEye);
    } else {
      setIsHiddenForgot(closedEye);
    }
  };

  const toggleHiddenForgotConfirm = () => {
    setConfirmForgotLoginPasswordVisible(!confirmForgotLoginPasswordVisible);
    if (isHiddenForgotConfirm === closedEye) {
      setIsHiddenForgotConfirm(openEye);
    } else {
      setIsHiddenForgotConfirm(closedEye);
    }
  };

  const toggleHiddenRegister = () => {
    setNewLoginPasswordVisible(!newLoginPasswordVisible);
    if (isHiddenRegister === closedEye) {
      setIsHiddenRegister(openEye);
    } else {
      setIsHiddenRegister(closedEye);
    }
  };

  const toggleHiddenRegisterConfirm = () => {
    setConfirmLoginPasswordVisible(!confirmLoginPasswordVisible);
    if (isHiddenConfirm === closedEye) {
      setIsHiddenConfirm(openEye);
    } else {
      setIsHiddenConfirm(closedEye);
    }
  };

  const toggleHidden = () => {
    setLoginPasswordVisible(!loginPasswordVisible);
    if (isHidden === closedEye) {
      setIsHidden(openEye);
    } else {
      setIsHidden(closedEye);
    }
  };

  const toggle = tab => {
    if (currentActiveTab !== tab) {
      if (startGameTime != "") {
        const withinSecond = new Date().getTime() - startGameTime < delayTime;
        if (miniGamesFirstClicked.indexOf(tab) == -1) {
          if (!withinSecond) {
            setCurrentActiveTab(tab);
            setIsLoading(true);
            startMiniGame(tab, 'd');
            setTimeout(function () {
              setMiniGamesFirstClicked([...miniGamesFirstClicked, tab]);
              setIsLoading(false);
            }, delayTime);
          }
        }
        else if (miniGamesFirstClicked.indexOf(currentActiveTab) != -1) {
          setCurrentActiveTab(tab);
        }
      }
    }
  }

  async function startMiniGame(miniGameCode, device) {

    // mini game have their own lang id
    let miniGameLangId = 0;

    if (activeLanguage == "en" || activeLanguage == "id") {
      miniGameLangId = 0;
    }
    else if (activeLanguage == "zh") {
      miniGameLangId = 1;
    }
    else if (activeLanguage == "ms") {
      miniGameLangId = 2;
    }
    else if (activeLanguage == "vi") {
      miniGameLangId = 3;
    }
    else if (activeLanguage == "th") {
      miniGameLangId = 4;
    }

    setStartGameTime("");
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_MINI_GAME_URL +
      "?miniGameCode=" + miniGameCode +
      "&device=" + device +
      "&languageId=" + miniGameLangId
    );

    if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
      throw responseJson[ApiKey._API_MESSAGE_KEY];
    }
    else {
      let data = responseJson[ApiKey._API_DATA_KEY];
      if (miniGameCode == "COINMINI") {
        setCoinMiniGameUrl(data);
        setStartGameTime(moment());
        setTimeout(function () {
          setIsLoading(false);
        }, 3000);
      }
      else if (miniGameCode == "KENOMINI") {
        setKenoMiniGameUrl(data);
        setStartGameTime(moment());
      }
      else {
        setTaiXiuMiniGameUrl(data);
        setStartGameTime(moment());
      }
    }
  }

  const handleLogout = async () => {
    _dispatch(setBusy());
    clearInterval(walletBalanceInterval.current);
    clearInterval(rolloverFlagInterval.current);
    clearInterval(rolloverInterval.current);
    await _dispatch(performLogout());
    setNavVisible(false);
    setCategoryActive("All");
    _dispatch(setIdle());
  }

  //async function getRolloverData() {
  //  var responseJson = await ApiEngine.get(ApiUrl._API_GET_ROLLOVER_RECORD);

  //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //    let data = responseJson[ApiKey._API_DATA_KEY];
  //    //setRolloverTarget(data[0].rolloverTarget);
  //    _dispatch(setRolloverTarget(data[0].rolloverTarget));
  //  }
  //}

  //const getProductCategory = async () => {
  //  let tempArr = [];
  //  var productCategoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
  //  if (productCategoryJson[ApiKey._API_SUCCESS_KEY]) {
  //    let data = productCategoryJson[ApiKey._API_DATA_KEY];
  //    data.forEach(x => {
  //      if (x.id > 0) {
  //        tempArr.push({ value: x.id.toString(), label: x.title, originalTitle: x.originalTitle });
  //      }
  //    });
      
  //    //setProductCategory(tempArr);
  //    _dispatch(setProductCategory(tempArr));
  //  }
  //}

  const getProductCategory2 = async () => {
    let tempArr = [];

    let categoryJson = await ApiEngine.get(
      ApiUrl._API_GET_PRODUCT_CATEGORY_DESKTOP +
      "?selectedLanguage=" +
      activeLanguage
    );
    if (categoryJson[ApiKey._API_SUCCESS_KEY]) {
      let data = categoryJson[ApiKey._API_DATA_KEY];
      data.forEach(x => {
        if (x.title !== "SlotMix") {
          tempArr.push({ value: x.id.toString(), label: x.title, originalTitle: x.originalTitle });
        }
      });

      setProductCategory2(tempArr);
    }
  }

  const checkTodayCheckInStatus = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_CHECK_TODAY_CHECK_IN_STATUS);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setIsTodayCheckIn(responseJson[ApiKey._API_DATA_KEY]);
    }
  }

  async function checkDailyCheckin(tempNavList1) {
    let responseJson = await ApiEngine.get(ApiUrl._API_CHECK_DAILY_CHECK_IN_STATUS);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      var status = responseJson[ApiKey._API_DATA_KEY];
      let tempNavList2 = [];
      if (status == true) {
        tempNavList2.push(
          {
            text: "CHECK_IN",
            icon: require("../assets/img/v3/coin-white.svg"),
            url: WebUrl._URL_DAILY_CHECK_IN,
            vicon: themeIcons.dailyCheckIn
          },
        )
        tempNavList1.splice(2, 0, tempNavList2[0]);
      }
      setNavList1(tempNavList1);
    }
  }

  const getCheckInModuleStatus = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_CHECK_DAILY_CHECK_IN_STATUS);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY] === true;
      setShowCheckIn(data);
    }
  }

  async function checkMemberTheme() {
    let tempIsWhiteTheme = _userData.selectedTheme === 1;
    let tempValue = tempIsWhiteTheme ? "light" : "default";
    if (themeName != tempValue) {
      await updateMemberTheme();
    }
  }

  async function updateMemberTheme() {
    if (isLoggedIn) {
      let tempIsWhiteTheme = _userData.selectedTheme === 1;
      let tempValue = !tempIsWhiteTheme ? "light" : "default";
      await _dispatch(setThemeName(tempValue));
      let params = {
        "selectedTheme": !tempIsWhiteTheme
      }
      let responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_MEMBER_SELECTED_THEME, createFormBody(params));
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(updateUserData());
      }
      else {
        _dispatch(showMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
      }
    }
    else {
      let tempValue = themeName === "light" ? "default" : "light";
      await _dispatch(setThemeName(tempValue));
    }
  }

  const getCompanyContent = async (/*page*/) => {
    //let responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_CONTENT + "?page=" + page + "&languageId=" + selectedLanguageId);
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_CONTENT + "?page=RegisterPopup&languageId=" + activeLanguageId);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY][0];
      setRegisterPopupContent(data["content"]);
      //let tempCompanyContentList = companyContentList;
      //if (tempCompanyContentList.length === 0 || tempCompanyContentList.filter(x => x.key === page).length === 0) {
      //  tempCompanyContentList.push(page, data["content"]);
      //  setCompanyContentList(tempCompanyContentList);
      //}
    }
  }

  const getQuickBetInfos = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEGATOTO_QUICKBET_INFO);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];

      setBetInfo(data.bet_info);
    }
  }

  return (
    <div>
      <div className="flex-container-between mb-3 pt-3">
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <img src={"/img/i8.live_2024" + (themeName === "light" ? "_alt" : "") + ".svg"} alt="i8" className="i8-logo-header img-responsive" /> 
        </div>
        <div className="header-lang-nav-container">
          <div>
            {showNavCountry &&
              <div className="show-country-bg-wrapper" onClick={() => setShowNavCountry(false)}>
                <div className="country-dropdown-container" style={showNavCountry ? { display: 'block' } : { display: 'none' }}>
                  {countryList.filter(x => isLoggedIn ? x.iso === 'MYR' : x) &&
                    countryList.filter(x => isLoggedIn ? x.iso === 'MYR' : x).length &&
                    map(countryList.filter(x => isLoggedIn ? x.iso === 'MYR' : x), (item, index) => {
                      return (
                        <div key={index}>
                          <div className={"country-row-container" + (selectionLangHeaderOption.name == item.name ? " active" : " ")}>
                            <div className="country-dropdown-items">
                              <div className="first">
                                <div>
                                  <img
                                    style={{ width: "18px" }}
                                    src={item.icon}
                                    alt={item.name}
                                  />
                                </div>
                                <div>
                                  {item.name}
                                </div>
                              </div>
                            </div>
                            <div className="country-child-container">
                              {item.child_languages &&
                                item.child_languages.length &&
                                map(item.child_languages, (child, index) => {
                                  return (
                                    <div key={index}>
                                      <div onClick={() => handleCountryOnClick(item, child.languageCode)} className={'country-child-lang' + (selectionLangHeaderOption.name == item.name && activeLanguageId == child.languageId ? ' active' : '')}>
                                        {child.name}
                                      </div>
                                    </div>
                                  );
                                })
                              }
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            }
            <div className="custom-select-container" onClick={() => setShowNavCountry(!showNavCountry)}>
              <div className="custom-select-field">
                {selectionLangHeaderOption.length != 0 ? (
                  <div>
                    <div>
                      <img
                        style={{ height: "18px", width: "18px" }}
                        src={selectionLangHeaderOption.icon_circle}
                        alt={selectionLangHeaderOption.name}
                      />
                    </div>
                    <div>
                      {selectionLangHeaderOption.iso}
                    </div>
                  </div>
                ) : (
                  <div>
                    {t("COUNTRY")}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={{ paddingLeft: "15px" }}>
            {/* <img src={require("../assets/img/v3/hamburger.png")} alt="arrow"  /> */}
            <VIcon
              icon={themeIcons.menu}
              className="theme-icon-size-20"
              onClick={() => setNavVisible(true)}
            />
          </div>
        </div>
      </div>
      <Slider {...bannerSlick} className="banner-slick main-banners">
        {mainBannerData &&
          mainBannerData.length &&
          map(mainBannerData, (item, index) => {
            return (
              <div
                key={index}
                className="banner-items"
                onClick={() => {
                  if (item.url === "undefined" || item.url === "#" || stringIsNullOrEmpty(item.url)) {
                    //do nothing
                  }
                  else {
                    if (isLoggedIn) {
                      if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(
                          JSON.stringify({
                            url: /(http(s?)):\/\//i.test(item.url) ? item.url : window.location.origin + "/" + item.url,
                          }));
                      } else {
                        window.open(item.url);
                      }
                    }
                    else {
                      _dispatch(
                        showMessage({
                          type: AlertTypes._INFO,
                          content: t("PLEASE_LOGIN_FIRST"),
                          onConfirm: () => {
                          },
                        })
                      );
                    }
                  }
                }}>
                <img src={item.image} alt="arrow" className="img-100" />
              </div>
            );
          })}
      </Slider>


      {isLoggedIn &&
        <div className="home-announcement mt-3">
          <div>
            {/* <img src={require("../assets/img/v3/mic.svg")} alt="arrow" /> */}
            <VIcon
              icon={themeIcons.dashboardAnnouncement}
              className="theme-icon-size-20"
            />
          </div>
          {!stringIsNullOrEmpty(content) &&
            <div style={{ width: "100%" }}>
              <marquee direction="left" scrollamount="3">
                <span>{parse(content)}</span>
              </marquee>
            </div>
          }
        </div>
      }

      {/* /////show when logout */}
      {!isLoggedIn &&
        <div className="login-register-btn-container mt-4">
          <div>
            <a className="btn login-btn" onClick={() => setLoginModalVisible(true)}>
              {t("LOGIN")}
            </a>
          </div>
          <div>
            <a className="btn register-btn" onClick={() => setRegisterModalVisible(true)}>
              {t("REGISTER")}
            </a>
          </div>
        </div>
      }
      {/* /////show when logout ending */}

      {/* /////show when login */}
      {isLoggedIn &&
        <>
          <div className="home-wallet-container mt-4">
            {/*<div className="home-member-details-wrapper">*/}
            {/*  <div className="home-username-wallet-box">*/}
            {/*    <div className="home-username-box">*/}
            {/*      <div>*/}
            {/*        {t("WELCOME") + "," + _userData.username}*/}
            {/*      </div>*/}
            {/*      <div className="ml-3">*/}
            {/*        <img src={require("../assets/img/v3/waving_small.svg")} alt="waving" />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="home-wallet-box">*/}
            {/*      <div className="d-flex mt-3">*/}
            {/*        <div className="home-wallet-balance" onClick={() => _history.push(WebUrl._URL_MAIN_WALLET)}>*/}
            {/*          <div className="home-wallet-icon">*/}
            {/*            <img src={require("../assets/img/v3/wallet.svg")} alt="wallet" />*/}
            {/*          </div>*/}
            {/*          <div style={{ marginLeft: "10px" }}>*/}
            {/*            <p className="mb-0">*/}
            {/*              {numberWithCurrencyFormat2((isNaN(currentWalletBalance) ? 0 : currentWalletBalance), 2, true)}*/}
            {/*            </p>*/}
            {/*          </div>*/}
            {/*          <div className="tooltip-text">{t("CLICK_TO") + " " + t("VIEW_WALLET_SMALL_CAP")}</div>*/}
            {/*        </div>*/}
            {/*        <div className="home-wallet-balance-update" onClick={() => updateUserWalletBalance()}>*/}
            {/*          <div>*/}
            {/*            <img src={require("../assets/img/v3/refresh-wallet-balance.svg")} alt="balance update" />*/}
            {/*          </div>*/}
            {/*          <div className="tooltip-text">{t("REFRESH_YOUR_WALLET_BALANCE")}</div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <div className="home-wallet-reload mt-3" onClick={() => _history.push(WebUrl._URL_DEPOSIT, { from: "/home" })}>*/}
            {/*        <div>*/}
            {/*          <img src={require("../assets/img/v3/plus.svg")} alt="reload" />*/}
            {/*        </div>*/}
            {/*        <div className="home-wallet-reload-text">*/}
            {/*          {t("RELOAD")}*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="qr-box-wrapper" onClick={() => _history.push(WebUrl._URL_MANAGE_REFERRAL_CODES)}>*/}
            {/*    */}{/* <img src={require("../assets/img/v3/walletQr.svg")} alt="wallet" /> */}
            {/*    <img src={require("../assets/img/v3/lucide_qr-code.png")} alt="wallet" />*/}
            {/*  </div>*/}
          {/*</div>*/}
          {
            themeName == "light" ?
              <>
                <div className="home-username-wallet-box-white-theme">
                  <div style={{ width: '15%' }}>
                    <img src={require("../assets/img/v3/i8-vip-icon.svg")} height="100%" width="100%" alt="waving" />
                  </div>
                  <div className="home-process-bar-box-white-theme">
                    <div>
                      {t("WELCOME_BACK_TITLE") + "," + _userData.username}
                    </div>
                    <div id="rollback-progress-bar">
                      <div className="progress-bar-container">
                        <div className="progress-bar-outline">
                          <div className="progress-bar-fill"
                            style={{
                              width: `${ 100 - ((Math.abs(rolloverTarget - rolloverProcess) / (rolloverProcess + (rolloverTarget - rolloverProcess))) * 100) }%`
                            }}
                          >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {rolloverProcess && rolloverTarget && 
                        <>{rolloverProcess} / {rolloverTarget}</>
                      }
                        
                    </div>
                  </div>
                  <div className="qr-box-wrapper" onClick={() => _history.push(WebUrl._URL_MANAGE_REFERRAL_CODES)}>
                    <img src={require("../assets/img/v3/referral-setting-icon.png")} alt="wallet" width="100%" height="100%" />
                  </div>
                </div>
                <hr className="hr-white-theme" />
              </>
              :
              <div className="home-username-wallet-box">
                <div className="home-username-box">
                  <div>
                    {t("WELCOME") + "," + _userData.username}
                  </div>
                  <div className="ml-3">
                    <img src={require("../assets/img/v3/waving_small.svg")} height="100%" width="100%" alt="waving" />
                  </div>
                </div>
                <div className="qr-box-wrapper" onClick={() => _history.push(WebUrl._URL_MANAGE_REFERRAL_CODES)}>
                  {/* <img src={require("../assets/img/v3/walletQr.svg")} alt="wallet" /> */}
                  <img src={require("../assets/img/v3/referral-setting-icon.png")} alt="wallet" width="100%" height="100%" />
                </div>
              </div>
          }
          <div className="home-wallet-box mt-3">
            <div className="home-wallet-box-balance">
              <div className="home-wallet-balance" onClick={() => _history.push(WebUrl._URL_MAIN_WALLET)}>
                <div className="home-wallet-icon">
                  <img src={themeName == "light" ? require("../assets/img/v3/wallet-white-theme.svg") : require("../assets/img/v3/wallet.svg") } height="100%" width="100%" alt="wallet" />
                </div>
                <div>
                  <p className="home-wallet-balance-amount">
                    {numberWithCurrencyFormat((isNaN(currentWalletBalance) ? 0 : currentWalletBalance), 2, true)}
                  </p>
                </div>
                <div className="tooltip-text">{t("CLICK_TO") + " " + t("VIEW_WALLET_SMALL_CAP")}</div>
              </div>
                 <div className="home-wallet-balance-update" onClick={() => updateUserWalletBalance(true)}>
                 <div>
                 {(!refreshWalletIsSpining && !refreshBalanceSpin) &&
                 <img className="flip" src={refreshIconStatic} alt="balance update" width="100%" height="100%" />
                 }
                 {(refreshWalletIsSpining && !refreshBalanceSpin) &&
                 <img className="flip" src={`${refreshIcon}?t=${new Date().getTime()}`} alt="balance update" width="100%" height="100%" />
                 }
                 {refreshBalanceSpin && 
                 <img className="" src={`${CompleteIcon}?t=${new Date().getTime()}`} alt="balance update" width="100%" height="100%" />
                 }
                   </div>
                <div className="tooltip-text">{t("REFRESH_YOUR_WALLET_BALANCE")}</div>
              </div>
            </div>
            <div className="home-wallet-box-transaction">
              <div className="home-wallet-reload" onClick={() => _history.push(WebUrl._URL_DEPOSIT, { from: WebUrl._URL_MAIN })}>
                <div>
                  <img src={require("../assets/img/v3/plus.svg")} alt="reload" width="100%" height="100%" />
                </div>
                <div className="home-wallet-reload-text">
                  {t("RELOAD")}
                </div>
              </div>
              {showCheckIn && <div className="home-wallet-check-in" onClick={() => _history.push(WebUrl._URL_DAILY_CHECK_IN, { from: WebUrl._URL_MAIN })}>
                {!isTodayCheckIn && <div class="red-dot"></div>}
                <div>
                  <Icon path={mdiCalendarRange} title="CheckIn" size={1} color="#ffd928" />
                </div>
              </div>}
            </div>

          </div>
            <div className="home-points-list mt-4">
              {pointsList &&
                pointsList.length &&
                map(pointsList, (item, index) => {
                  return (
                    <div key={index} className="home-points-items" onClick={() => {
                      if (item.url == WebUrl._URL_REWARDS) {
                        // this purpose is determine click from bottom tab in react native or click from web app
                        _history.push(item.url, { from: window.location.pathname });
                      }
                      else {
                        _history.push(item.url)
                      }
                    }}>
                      <div className="home-points-icon">
                        <VIcon
                          icon={item.icon}
                          className={"vicon" + index}
                        />
                      </div>
                      <div>
                        <div className="points-list-title">
                          <h6 className="mb-0">{t(item.title)}</h6>
                        </div>
                        <p className="mb-0">{item.points}</p>
                      </div>
                      {/*!stringIsNullOrEmpty(item.tooltipText) && <div className="tooltip-text text-lowercase">{t("CLICK_TO_VIEW") + " " + t(item.tooltipText)}</div>*/}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="home-button-list mt-3">
            {buttonList &&
              buttonList.length &&
              map(buttonList, (item, index) => {
                return (
                  <div key={index} className="home-button-items">
                    <a className="items-box icon" onClick={() => {
                      if (item.href == WebUrl._URL_WITHDRAWAL) {
                        // this purpose is determine click from bottom tab in react native or click from web app
                        _history.push(item.href, { from: window.location.pathname })
                      }
                      else {
                        _history.push(item.href)
                      }
                    }
                    }>
                      <div className="image">
                        {/* <img src={item.image} alt="buttons" /> */}
                        <VIcon
                          icon={item.icon}
                          className={"vicon vicon" + index}
                        />
                      </div>
                      <div className="text">
                        <span>{t(item.name)}</span>
                      </div>
                    </a>
                  </div>
                );
              })}
          </div>
        </>
      }
      <hr className="hr-left" />
      <div className="">
        <h3>{t("GAMES_CATEGORIES")}</h3>
        <p className="under-title-text">
          {t("BROWSE_GAMES_FROM_CATEGORIES")}
        </p>
        {/*<div className="home-games-categories">*/}
        {/*  <div className="first">*/}
        {/*    <div*/}
        {/*      className={categoryActive == "Slots" ? "category-active" : ""}*/}
        {/*      onClick={() => handleCategoryClick("Slots")}*/}
        {/*      style={{ outline: categoryActive == "Slots" ? "1px solid #00f8ff" : "1px solid transparent" }}*/}
        {/*    >*/}
        {/*      <img src={require("../assets/img/v3/homepage_slot.svg")} alt="slot" />*/}
        {/*      <div className="home-game-category-label">{t("Slots")}</div>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*      className={categoryActive == "Poker" ? "category-active" : ""}*/}
        {/*      onClick={() => handleCategoryClick("Poker")}*/}
        {/*      style={{ outline: categoryActive == "Poker" ? "1px solid #7743ff" : "1px solid transparent" }}*/}
        {/*    >*/}
        {/*      <img src={require("../assets/img/v3/homepage_poker.svg")} alt="poker" />*/}
        {/*      <div className="home-game-category-label">{t("Poker")}</div>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*      className={categoryActive == "Sports" ? "category-active" : ""}*/}
        {/*      onClick={() => handleCategoryClick("Sports")}*/}
        {/*      style={{ outline: categoryActive == "Sports" ? "1px solid #1400ff" : "1px solid transparent" }}*/}
        {/*    >*/}
        {/*      <img src={require("../assets/img/v3/sports2.svg")} alt="sports" />*/}
        {/*      <div className="home-game-category-label sport">{t("Sports")}</div>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*      className={categoryActive == "Lottery" ? "category-active" : ""}*/}
        {/*      onClick={() => handleCategoryClick("Lottery")}*/}
        {/*      style={{ outline: categoryActive == "Lottery" ? "1px solid #c8ff00" : "1px solid transparent" }}*/}
        {/*    >*/}
        {/*      <img*/}
        {/*        src={require("../assets/img/v3/homepage_lottery_2.svg")}*/}
        {/*        alt="lottery"*/}
        {/*      />*/}
        {/*      <div className="home-game-category-label">{t("Lottery")}</div>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*      className={categoryActive == "Fishing" ? "category-active" : ""}*/}
        {/*      onClick={() => handleCategoryClick("Fishing")}*/}
        {/*      style={{ outline: categoryActive == "Fishing" ? "1px solid #00dcff" : "1px solid transparent" }}*/}
        {/*    >*/}
        {/*      <img*/}
        {/*        src={require("../assets/img/v3/homepage_fishing.svg")}*/}
        {/*        alt="Fishing"*/}
        {/*      />*/}
        {/*      <div className="home-game-category-label">{t("Fishing")}</div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="second">*/}
        {/*    <div*/}
        {/*      className={"category-active-93connect"}*/}
        {/*      onClick={() => handleCategoryClick("93Connect")}*/}
        {/*    >*/}
        {/*      <img src={require("../assets/img/v3/93Connect.png")} alt="93connect" />*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*      className={categoryActive == "Casino" ? "category-active" : ""}*/}
        {/*      onClick={() => handleCategoryClick("Casino")}*/}
        {/*      style={{ outline: categoryActive == "Casino" ? "1px solid #d57e01" : "1px solid transparent" }}*/}
        {/*    >*/}
        {/*      <img src={require("../assets/img/v3/homepage_casino.svg")} alt="casino" />*/}
        {/*      <div className="home-game-category-label">{t("Casino")}</div>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*      className={categoryActive == "eSports" ? "category-active" : ""}*/}
        {/*      onClick={() => handleCategoryClick("eSports")}*/}
        {/*      style={{ outline: categoryActive == "eSports" ? "1px solid #ff003d" : "1px solid transparent" }}*/}
        {/*    >*/}
        {/*      <img src={require("../assets/img/v3/homepage_esports.svg")} alt="esport" />*/}
        {/*      <div className="home-game-category-label">{t("eSports")}</div>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*      className={categoryActive == "Cockfighting" ? "category-active" : ""}*/}
        {/*      onClick={() => handleCategoryClick("Cockfighting")}*/}
        {/*      style={{ outline: categoryActive == "Cockfighting" ? "1px solid #fdaba2" : "1px solid transparent" }}*/}
        {/*    >*/}
        {/*      <img src={require("../assets/img/v3/cockfight.svg")} alt="Cockfighting" />*/}
        {/*      <div className="home-game-category-label cockfight">{t("Cockfighting")}</div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className="home-games-new-categories">
          <div className="first">
            {productCategory2.map((item, index) => {
              return (
                <div
                  className={categoryActive == item.originalTitle ? "new-category-active" : ""}
                  onClick={() => handleCategoryClick(item.originalTitle)}
                >
                  <img className={categoryActive !== item.originalTitle ? "category-img" : "category-img category-img-active"} src={"https://cdn.i8global.com/i8app/product-category/icon-" + item.originalTitle + ".svg"} alt={item.originalTitle} />
                  <div className={categoryActive !== item.originalTitle ? "new-home-game-category-label" : "new-home-game-category-label-active"}>{t(item.originalTitle)}</div>
                </div>
              );
            })}
          </div>
        </div>

        <hr className="custom-hr" style={{ marginTop: "6px" }} />
        {Object.keys(masterProviderList).length > 0 &&
          <>{<>
            {isGettingGames ? <div className="provider-spinner"><Spinner animation="border" role="status"></Spinner></div> : <div id="provider-section">
              <div className="new-provider-parent-container">
              {masterProviderList
                .map((provider, index) => {
                  return (
                    //<div key={index} className={"provider-category-container mb-3"}>
                    //  {
                    //    masterProviderList[list].map((provider, i) => {
                    //      return (
                    //        <div key={i}>
                    //          <div className={"provider-item" + (providerListRowNum === index + 1 && activeProvider === i ? " provider-active" : "")} onClick={() => handleProviderClick(index, i, provider)}>
                    //            <div className={"provider-logo-div" + (provider.isUM ? " um" : "")} id={provider.productName} style={{ backgroundImage: `url(${provider.productImage}` }}></div>
                    //            {provider.isUM && <div className="um-icon-div" style={{ backgroundImage: `url(${ProviderUMIcon})` }}></div>}
                    //          </div>
                    //        </div>)
                    //    })
                    //  }
                    //</div>
                    <div className="provider-image new-provider-logo-div" onClick={() => handleProviderClick(provider)}>
                      {!stringIsNullOrEmpty(provider.productGame) && provider.productGame["isRecommended"] ? <div className="new-recommended-icon-div" style={{ backgroundImage: `url(${ProviderHOTIcon})` }}></div> : <></>}
                      <img className={"provider-image" + (provider.isUM ? " um" : "")} id={provider.productName} src={provider.productImage} alt={provider.productName} />
                      {provider.isUM && <div className="new-um-icon-div" style={{ backgroundImage: `url(${ProviderUMIcon})` }}></div>}
                      
                    </div>
                  )
                })
                }
              </div>
              <div className="new-provider-view-more-button">
              <div>
                {
                  isShowMoreProvider && <button className="new-provider-more-button" onClick={() => {
                    _history.push({
                      pathname: WebUrl._URL_PROVIDER_LOBBY.replace(":category", categoryActive).replace(":provider?", ""),
                      state: {
                        "providerList": allAvailableProvidersList
                      }
                    })
                }}><span>{t("MORE")}</span></button>
                }
              </div>
            </div>
            </div>}
          </>}</>
        }
      </div>

      <hr className="hr-right" />
      <ProgressiveJackpot />

      {isLoggedIn &&
        <>
          <hr className="hr-left" />
          <div className="mega3d-home-wrapper">
            <div className="">
              <h3>{t("MEGATOTO_3D_BONUS")}</h3>
              <p className="under-title-text">
                {t("EASY_BETS")}
              </p>
            </div>
            <div className="mega3d-rules-img">
              <img
                src={`/img/v3/mega3d_quickplay/Info_icon.svg`}
                alt="info"
                width="50"
                height="35"
                onClick={() => {window.open(betInfo.rules_url, '_blank')}}
              />
            </div>
          </div>
          <MegatotoQuickBet />
        </>
      }

      {promoBannerData.length > 0 ? (isLoggedIn && !isLoadingMemberDatas && !isLoadingRolloverDatas && currentRolloverBalance > 0) ? <hr className="hr-left" /> : <hr className="hr-right" /> : <></>}

      {promoBannerData.length > 0 ? <div>
        <div className="homepage-promotion-header">
          <h3>{t("HOMEPAGE_PROMOTION")}</h3>
          <div className="more-promotion" onClick={() => _history.push(WebUrl._URL_PROMOTION)}>{t("MORE_PROMOTION")}</div>
        </div>
        <Slider {...promoBannerSlick} className="mobile-promo-slider">
          {promoBannerData.map((banner, index) => {
            return (
              <div key={index} className={"mobile-promo-banner promo-" + (index + 1)} onClick={() => _history.push(WebUrl._URL_PROMOTION)}>
                <img className="mobile-promo-img" src={banner.image} alt="promotion-banner" />
              </div>
            )
          })}
        </Slider>
      </div> : <></>}
      {(promoBannerData.length > 0 && isLoggedIn && currentRolloverBalance > 0) && <hr className="custom-hr" />}

      {(isLoggedIn && !isLoadingMemberDatas && !isLoadingRolloverDatas && currentRolloverBalance > 0) && <hr className="hr-right" />}

      {(!isLoadingMemberDatas && !isLoadingRolloverDatas) ? <>{isLoggedIn && productCategory && currentRolloverBalance !== 0 ?
        <div>
          <div className="special-rollover-container">
            <div className="text-n-icon">
              <h1>{t("REMAINING_ROLLOVER")}</h1>
              <div className="icon-container">
                {productCategory.map((item, index) => {
                  return (
                    <div className={`inner-container ${[...playableCategory].includes(item.originalTitle) ? "playable" : ""}`}>
                      <img
                        src={"https://cdn.i8global.com/i8app/product-category/icon-" + item.originalTitle + ".svg"}
                        className="img-responsive category-img-active"
                        alt={item.label + " image"}
                        width="30"
                        height="30"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="progress-bar-container">
              <div className="progress-bar-outline">
                <span style={{ position: "absolute" }}>{numberWithCurrencyFormat2((Math.abs(rolloverTarget - currentRolloverBalance) / (currentRolloverBalance + (rolloverTarget - currentRolloverBalance))) * 100, 2, true)}%</span>
                <div className="progress-bar-fill"
                  style={{
                    width: `${(Math.abs(rolloverTarget - currentRolloverBalance) / (currentRolloverBalance + (rolloverTarget - currentRolloverBalance))) * 100}%`
                  }}
                >
                </div>
              </div>
            </div>
            <div className="rollover-text">
              {t("AMOUNT")}: RM {numberWithCurrencyFormat2(Math.abs(rolloverTarget - currentRolloverBalance), 2, true) + " / " + numberWithCurrencyFormat2((currentRolloverBalance + (rolloverTarget - currentRolloverBalance)), 2, true)} <span style={{ color: "#FFD928" }}></span>
            </div>
          </div>
        </div>
        :
        <></>
      }</> : <></>}

      {(isLoadingRecommendedMatches || recommendedMatches.length > 0) && <hr className="hr-left" />}
      <div className="mt-4">
        {
          isLoadingRecommendedMatches ?
            <div className="provider-spinner"><Spinner animation="border" role="status"></Spinner></div> :
            <div className="recommended-match-container">
              {map(recommendedMatches, (item, index) => {
                return (
                  <div className={"recommended-match-items " + classnames({ active: Object.keys(selectedRecommendedMatch).length > 0 && selectedRecommendedMatch.matchId === item.matchId })} key={index} onClick={() => { setSelectedRecommendedMatch(item); }}>

                    <div className="recommended-match-date-time">
                      {!stringIsNullOrEmpty(item.gameTime) ? <span className="dot"></span> : <i className="fa fa-clock mr-2"></i>}
                      {moment(item.kickOffTimeGMT).format("DD/MM HH:mm") + " GMT+8"}
                    </div>
                    <div className="recommended-match-team-wrapper">
                      <div className="recommended-match-team">
                        <div className="team-logo">
                          <img src={item.homeTeamIconUrl} alt="homeLogo" />
                        </div>
                        <div className="team-name">
                          {item.homeName}
                        </div>
                      </div>
                      <div className="recommended-match-team">
                        <div className="team-logo">
                          <img src={item.awayTeamIconUrl} alt="awayLogo" />
                        </div>
                        <div className="team-name">
                          {item.awayName}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
        }

        {
          Object.keys(selectedRecommendedMatch).length > 0 && (
            <div className="selected-recommended-match-container mt-4">
              <div className="selected-recommended-match-details">
                <div className="topbar">
                  <div className="vendor-logo">
                    <img src="/img/v3/selected_recommended_match_vendor.svg" alt="vendor-logo" />
                  </div>
                  <div className="date-time">
                    {moment(selectedRecommendedMatch.kickOffTimeGMT).format("DD/MM/YYYY HH:mm") + " GMT+8"}
                  </div>
                  <div className="close-match" onClick={() => { setSelectedRecommendedMatch({}); }} >{t("CLOSE")}</div>
                </div>
                {!stringIsNullOrEmpty(selectedRecommendedMatch.gameTime) ? <div className="live">
                  <span className="dot"></span>{t("LIVE") + " (" + selectedRecommendedMatch.gameTime + ")"}
                </div> : <div className="live">
                  {t("COMING_SOON_2")}
                </div>}

                <div className="match">
                  <div className="team-logo">
                    <img src={selectedRecommendedMatch.homeTeamIconUrl} alt="homeLogo" />
                    <div className="team-name">{selectedRecommendedMatch.homeName}</div>
                    {!stringIsNullOrEmpty(selectedRecommendedMatch.gameTime) && <div className="team-score">{selectedRecommendedMatch.homeScore}</div>}
                  </div>
                  <div className="versus">{t("VS")}</div>
                  <div className="team-logo">
                    <img src={selectedRecommendedMatch.awayTeamIconUrl} alt="awayLogo" />
                    <div className="team-name">{selectedRecommendedMatch.awayName}</div>
                    {!stringIsNullOrEmpty(selectedRecommendedMatch.gameTime) && <div className="team-score">{selectedRecommendedMatch.awayScore}</div>}
                  </div>
                </div>
              </div>
              <button className="selected-recommended-match-button" onClick={() => { startGame568SportsBook(selectedRecommendedMatch.matchId); }}><span>{t("BET_NOW")}</span></button>
            </div>

          )
        }

      </div>
      {(isLoadingRecommendedMatches || recommendedMatches.length > 0) && <hr className="hr-right" />}
      {/* /////show when login ending */}
      {/*isRecommendedMobileGame && isRecommendedMobileGame.length > 0 &&
        <>
          <div className="">
            <h3>{t("POPULAR_GAMES")}</h3>
            <p className="under-title-text">
              {t("BROWSE_GAMES_FROM_CATEGORIES")}
            </p>
            <Slider {...popularSlick} className="gamelist-slick">
              {map(isRecommendedMobileGame, (item, index) => {
                return (
                  <div className="gamelist-items" key={index} onClick={() => { if (!item.gameUm) { startGame(item); } }}>

                    <div className="gamelist-img-container">


                      <div className="tag">
                        <img
                          src={require("../assets/img/assets/hot.png")
                          }
                          className="img-responsive"
                          alt={"hot"}
                        />
                      </div>
                      <div className="pupular-pointer">
                        {index + 1}
                      </div>

                      <div className={"popular-game-wrapper" + (item.gameUm ? " um" : "")}>

                        <img
                          src={item.popularImage != null ? item.popularImage : item.image}
                          alt={item.gameName}
                          className="img-100"
                        />
                        {item.gameUm && <div className="um-icon" style={{ backgroundImage: `url(${UMIcon})` }}></div>}
                      </div>
                      <div className="gamelist-img-inner-shadow"></div>
                      <div className="gamelist-provider">
                        {item.providerName}
                      </div>
                    </div>
                    <div className="gamelist-title mt-2">{item.gameName}</div>
                  </div>
                );
              })}
            </Slider>
          </div>
          <hr className="custom-hr" />
        </>
      */}
      {subBannerData && subBannerData.length > 0 &&
        <>
          <div className="promotion-banner">
            <Slider {...bannerSlick} className="banner-slick sub-banners">
              {subBannerData.map((banner, index) => {
                return (
                  <div key={index}
                    onClick={() => {
                      if (banner.url === "undefined" || banner.url === "#" || stringIsNullOrEmpty(banner.url)) {
                        //do nothing
                      }
                      else {
                        if (isLoggedIn) {
                          if (window.ReactNativeWebView) {
                            window.ReactNativeWebView.postMessage(
                              JSON.stringify({
                                url: /(http(s?)):\/\//i.test(banner.url) ? banner.url : window.location.origin + "/" + banner.url,
                              }));
                          } else {
                            window.open(banner.url);
                          }
                        }
                        else {
                          _dispatch(
                            showMessage({
                              type: AlertTypes._INFO,
                              content: t("PLEASE_LOGIN_FIRST"),
                              onConfirm: () => {
                              },
                            })
                          );
                        }
                      }
                    }}
                  >
                    <img className="img-100" style={{borderRadius:'8px', display:"unset"}} src={banner.image} alt={banner.title} />
                  </div>
                )
              })}
            </Slider>
          </div>
          <hr className="hr-left" />
        </>
      }
      {(newGames &&
        newGames.length) ? <div className="">
        <h3>{t("NEW_GAMES")}</h3>
        <p className="under-title-text">
          {t("ENJOY_THE_NEWEST_GAMES")}
        </p>
        <div className="container p-0">
          <div className="games-icon-grid">
            {!newGamesIsLoading ?
              <>
                {map(newGames, (game, i) => {
                  return (
                    <div key={i} className={"hot-game-div-" + i}>
                      <div className="hot-games-tile" key={i}>
                        <a
                          key={i}
                          onClick={() => {
                            //startGame(game);
                            _dispatch(startGame(game))
                          }}
                          className={
                            isLoggedIn && game["gameUm"] == true ? "gameum" : "hot-games-link"
                          }
                          target="_blank"
                        >
                          <div
                            className="lobby-games-img"
                            style={{
                              backgroundImage: `url(${game.image})`
                            }}
                          >
                            <div className="gamelist-img-inner-shadow"></div>
                            {/*<div className="v2-um-tag" style={{ backgroundImage: `url(${UMIcon})` }}></div>*/}
                            {isLoggedIn && game["gameUm"] == true && (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  className="v2-um-tag game-category-label"
                                ></div>
                                <div
                                  style={{
                                    backgroundImage: `url(${UMIcon})`,
                                  }}
                                  className="v2-um-tag-icon"
                                ></div>
                              </>
                            )}
                          </div>
                          <div className="hot-games-name">
                            <div className="hot-games-text">
                              {game.gameName}
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  );
                })}
              </>
              :
              <Spinner className="new-games-spinner" animation="border" role="status" size="sm"><span className="sr-only">{t("LOADING")}...</span></Spinner>}
          </div>
        </div>
      <hr className="hr-right" />
      </div> : <></>}
      {isGameForYouMobileGame &&
        isGameForYouMobileGame.length ? <div className="pb-5">
        <h3>{t("GAMES_FOR_YOU")}</h3>
        <p className="under-title-text">
          {t("CURATED_GAME_LIST")}
        </p>
        <Slider {...popularSlick} className="gamelist-slick">
          {isGameForYouMobileGame &&
            isGameForYouMobileGame.length &&
            map(isGameForYouMobileGame, (item, index) => {
              return (
                <div key={index} className="gamelist-items" onClick={() => /*startGame(item)*/ _dispatch(startGame(item))}>
                  <div className="gamelist-img-container">
                    <div className={"curated-game-wrapper" + (item.gameUm ? " um" : "")}>
                      <img
                        src={item.image}
                        alt={item.gameName}
                        className="img-100"
                      />
                      {item.gameUm && <div className="um-icon" style={{ backgroundImage: `url(${UMIcon})` }}></div>}
                    </div>
                    <div className="gamelist-img-inner-shadow"></div>
                    <div className="gamelist-provider">
                      {item.providerName}
                    </div>
                  </div>
                  <div className="gamelist-title mt-2">{item.gameName}</div>
                </div>
              );
            })}
        </Slider>
      </div> : <></>}
      <DownloadApp />
      <div id="footer" className="footer">
        <div className="mobile-footer-content-wrapper">
          <div className="licence-text">{i18n.t("GAMING_LICENCE")}</div>
          <div
            className="licence-image"
            style={themeName === "light" ? { backgroundImage: `url(${PacgorLogoLT})`, width: "100px", height: "50px" } : { backgroundImage: `url(${PacgorLogo})`, width: "100px", height: "50px" }}
          ></div>
          <div className="welcome-msg">{i18n.t("GAMING_LICENCE_DETAILS")}</div>
          <div className="cert-text">{i18n.t("CERTIFICATION")}</div>
          <div className="cert-icon-list">
            {certList && certList.map((cert, index) => {
              return (
                <div
                  className="cert-icon"
                  key={index}
                  style={themeName === "light" ? { backgroundImage: `url(${cert.imageLight})`, width: "100px", height: "50px" } : { backgroundImage: `url(${cert.image})`, width: "100px", height: "50px" }}
                >
                </div>
              )
            })}
          </div>
          <div className="security-text">{i18n.t("SECURITY")}</div>
          <div className="security-icon-list">
            {securityList && securityList.map((security, index) => {
              return (
                <div
                  className="security-icon"
                  key={index}
                  style={themeName === "light" ? { backgroundImage: `url(${security.imageLight})`, width: "100px", height: "50px" } : { backgroundImage: `url(${security.image})`, width: "100px", height: "50px" }}
                // style={{ backgroundImage: `url(${security.image})`, width: "100px", height: "50px" }}
                >
                </div>
              )
            })}
          </div>
          <div className="payment-method-text">{i18n.t("PAYMENT_METHOD")}</div>
          {themeName === "light" ? (
            <>
              <div style={{ marginBottom: '20px' }}>
                <img style={{paddingLeft: '-5px'}} src={require("../assets/img/v3/payment/Light_payment.svg")} alt="payment gateways" className="img-responsive" />
              </div>
            </>
          ) : (
            <>
                <div style={{ marginBottom: '20px' }}>
                  <img style={{ paddingLeft: '-5px' }} src={require("../assets/img/v3/payment/dark_payment.svg")} alt="payment gateways" className="img-responsive" />
              </div>
            </>
          )}
          
          {/* <div className="payment-method-icon-list">
            {paymentMethodList && paymentMethodList.map((paymentMethod, index) => {
              return (
                <div
                  className="payment-method-icon"
                  key={index}
                  style={themeName === "light" ? { backgroundImage: `url(${paymentMethod.imageLight})` } : { backgroundImage: `url(${paymentMethod.image})` }}
                >
                </div>
              )
            })}
          </div> */}

          <div className="round-icons-list-wrapper">
            <div className="social-media">
              <div>{i18n.t("FOLLOW_US_FOOTER")}</div>
              <div className="social-media-icon-list">
                {footerSocialMediaList && footerSocialMediaList.map((socialMedia, index) => {
                  return (
                    <div 
                      className="social-media-icon" 
                      key={index} 
                      style={{ backgroundImage: themeName === "light" ? `url(${socialMedia.imageLight})` : `url(${socialMedia.image})`}} 
                      onClick={() => window.open(socialMedia.link)}>
                        {/* // <img src="" alt="social media" /> */}
                    </div>
                  )
                })}
              </div>
            </div>

            {!window.ReactNativeWebView &&
              <div className="recommended-browser">
                <div className="text">{i18n.t("RECOMMENDED_BROWSER")}</div>
                <div className="recommended-browser-icon-list">
                  {recommendedBrowserList && recommendedBrowserList.map((recommendedBrowser, index) => {
                    return (
                      <div className="recommended-browser-icon" key={index} style={{ backgroundImage: themeName === "light" ? `url(${recommendedBrowser.imageLight})` : `url(${recommendedBrowser.image})` }}>
                      </div>
                    )
                  })}
                </div>
              </div>
            }

            <div></div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="copyright-text">
              {i18n.t("COPYRIGHT")}
            </div>
            <div className="version-number">Ver. {process.env.REACT_APP_VERSION}</div>
          </div>

        </div>
      </div>
      <LiveChatModal />

      <Modal
        id="custom-modal"
        contentClassName="modal-brand modal-bottom"
        fade={true}
        isOpen={loginModalVisible}
        toggle={() => {
          setLoginModalVisible(false);
        }}
        centered
      >
        <ModalBody>
          <form onSubmit={loginHandleSubmit(submitLoginForm)}>
            <div className="flex-box-centered mb-1">
              <h2 className="custom-modal-title mb-0 mr-3">{t("WELCOME_BACK_TITLE")}</h2>
              <div>
                <img src={require("../assets/img/v3/waving.png")} alt="waving" className="img-responsive" />
              </div>
            </div>
            <p className="pop-up-under-title-text mb-4 text-center">{t("WELCOME_BACK")}</p>
            <div className="input-container">
              <label>{t("USERNAME")}</label>
              <div className="input-with-icon-container">
                <div className="input-icon">
                  <VIcon
                    icon={themeIcons.user}
                    className="theme-icon-size-20"
                  />
                </div>
                <input
                  type='text'
                  id="loginUsername"
                  className="custom-input"
                  placeholder={t("USERNAME")}
                  defaultValue={ls.get("gR97lPRIn797")["PYTLczcty9uH"] && ls.get("gR97lPRIn797")["Do3SwI4cuvqR"] ? ls.get("gR97lPRIn797")["Do3SwI4cuvqR"] : ""}
                  name="username"
                  ref={loginRegister({ required: true })}
                />
              </div>
            </div>
            <div className="input-container mt-3">
              <label>{t("PASSWORD")}</label>
              <div className="input-with-icon-container">
                <div className="input-icon">
                  {/* <img src={require("../assets/img/v3/key.png")} alt="password" className="img-responsive" /> */}
                  <VIcon
                    icon={themeIcons.key}
                    className="theme-icon-size-20"
                  />
                </div>
                <input
                  type={loginPasswordVisible ? 'text' : 'password'}
                  id="loginPassword"
                  className="custom-input input-hidden"
                  placeholder={t("PASSWORD")}
                  defaultValue={ls.get("gR97lPRIn797")["PYTLczcty9uH"] && ls.get("gR97lPRIn797")["gR1IPGBN01dS"] ? ls.get("gR97lPRIn797")["gR1IPGBN01dS"] : ""}
                  name="password"
                  ref={loginRegister({ required: true })}
                />
                <div className="input-hidden-icon" onClick={toggleHidden}>
                  {/* <img src={isHidden} alt="password" className="img-responsive" /> */}
                  <VIcon
                    icon={isHidden}
                    className="theme-icon-size-20"
                  />
                </div>
              </div>
            </div>
            <label className="custom-checkbox mt-4">
              <input type="checkbox" className="form-checkbox-label" defaultChecked={ls.get("gR97lPRIn797")["PYTLczcty9uH"]} name="isKeepedPassword" ref={loginRegister()} />
              <span className="checkmark"></span>
              {t("REMEMBER_ME")}
            </label>
            <div className="mt-3 login-btn-container">
              <button type="submit" style={{ width: "100%" }} className="btn custom-btn mt-4">
                {t("LOGIN")}
              </button>
              <span>{t("REGISTER_TEXT")}<a className="register-now-text" onClick={() => { setLoginModalVisible(false); setRegisterModalVisible(true); }}>{t("REGISTER_NOW")}</a></span>
              <a className="forgot-password" onClick={() => (setLoginModalVisible(false), setForgotPasswordModalVisible(true))}>
                {t("FORGOT_PASSWORD") + "?"}
              </a>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal
        id="custom-modal"
        className="modal-height-100"
        contentClassName="modal-brand modal-bottom"
        fade={false}
        isOpen={forgotPasswordModalVisible}
        centered
      >
        <ModalBody style={{ padding: "40px 20px 28px" }}>
          {forgotPasswordSteps == 1 && (
            <form onSubmit={forgotPasswordHandleSubmit(submitForgotPasswordForm)}>
              <div className="flex-spacebetween-row">
                <div>
                  <div className="modal-custom-header mb-4">
                    <div onClick={() => (setForgotPasswordModalVisible(false))}>
                      {/* <img src={require("../assets/img/v3/back-arrow.png")} alt="back" className="img-responsive" /> */}
                      <VIcon
                        icon={themeIcons.angleLeft}
                        className="theme-icon-size-20"
                      />
                    </div>
                    <h2 className="mb-0">{t("FORGOT_PASSWORD")}</h2>
                  </div>
                  <p className="pop-up-under-title-text mb-4 text-center">{t("OTP_TO_RESET_BY_SMS")}</p>
                  <div className="input-container">
                    <div className="input-with-icon-container">
                      <input
                        type='text'
                        className="custom-input"
                        id="username"
                        name="username"
                        autoComplete={"off"}
                        ref={forgotPasswordRegister({
                          required: t("FIELD_REQUIRED"),
                        })}
                        error={forgotPasswordErrors}
                        placeholder={t("USERNAME")}
                      />
                      <div className="invalid-feedback">
                        {forgotPasswordErrors.username
                          ? t(forgotPasswordErrors.username.message)
                          : ""}
                      </div>
                      <div className="input-icon">
                        {/* <img src={require("../assets/img/v3/username.png")} alt="username" className="img-responsive" /> */}
                        <VIcon
                          icon={themeIcons.user}
                          className="theme-icon-size-20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button style={{ width: "100%" }} className="btn custom-btn mt-5" type="submit">{t("SEND_VIA_SMS")}</button>
                </div>
              </div>
            </form>
          )}

          {forgotPasswordSteps == 2 && (
            <form onSubmit={tacVerifyHandleSubmit(submitVerifyTAC)}>
              <div className="flex-spacebetween-row">
                <div>
                  <div className="modal-custom-header mb-4">
                    <div onClick={() => (setForgotPasswordSteps(1), setShowKeyboard(false))}>
                      <VIcon
                        icon={themeIcons.angleLeft}
                        className="theme-icon-size-20"
                      />
                    </div>
                    <h2 className="mb-0 login-modal-text">{t("FORGOT_PASSWORD")}</h2>
                  </div>
                  <p className="pop-up-under-title-text mb-4 text-center">{t("PHONE_OTP_SEND_TO")}{tempPhoneNum}</p>
                  <div className="custom-pin-container tac-container" onKeyPress={(e) => e.preventDefault()}>
                    <PinInput style={{ marginBottom: "35px" }}
                      ref={eleFinalPin}
                      length={6}
                      initialValue=""
                      secret={true}
                      focus={forgotPasswordModalVisible && forgotPasswordSteps == 2}
                      autoComplete="off"
                      autoSelect={true}
                      disabled={true}
                      onChange={(value, index) => {
                        setPinNumberFinalFocused(index);
                      }}
                      type="numeric"
                      inputMode="number"
                      regexCriteria={/^[ 0-9_@./#&+-]*$/}
                    />
                  </div>
                  {countdownTAC !== 0 &&
                    <span className="forgot-password mt-4">
                      {t("RESET_CODE_IN_SECONDS", { value: moment.duration(countdownTAC, 'milliseconds').asSeconds() })}
                    </span>
                  }
                </div>
              </div>
              {showKeyboard ?
                <Keyboard
                  style={{ backgroundColor: "unset" }}
                  layout={{
                    'default': [
                      "1 2 3 4 5 6 7 8 9 * 0 {bksp}"
                    ]
                  }}
                  display={{
                    '{bksp}': "<i className='fas fa-backspace'></i>",
                  }}
                  theme={`keyboard keyboard-numpad keyboard-numpad-v3 no-bg`}
                  keyboardRef={r => (keyboard.current = r)}
                  onKeyPress={onKeyPress}
                  disableButtonHold
                  disableCaretPositioning
                /> : <></>
              }
              {countdownTAC === 0 ? (
                <button className="btn btn-block btn-brand-gradient btn-done" onClick={() => verifyState.button = 2}>
                  {t("RESEND")}
                </button>
              ) : (
                <button className="btn btn-block btn-brand-gradient btn-done" type="submit" disabled={enteredPinNumberFinal.length != 6}>
                  {t("CONFIRM")}
                </button>
              )}
            </form>
          )}

          {forgotPasswordSteps == 3 && (
            <form onSubmit={resetPasswordHandleSubmit(submitNewPassword)}>
              <div className="flex-spacebetween-row">
                <div>
                  <div className="modal-custom-header mb-4">
                    <div onClick={() => (setForgotPasswordSteps(2))}>
                      {/* <img src={require("../assets/img/v3/back-arrow.png")} alt="back" className="img-responsive" /> */}
                      <VIcon
                        icon={themeIcons.angleLeft}
                        className="theme-icon-size-20"
                      />
                    </div>
                    <h2 className="mb-0">{t("CREATE_NEW_PASSWORD")}</h2>
                  </div>
                  <p className="pop-up-under-title-text mb-4 text-center">{t("CREATE_YOUR_NEW_PASSWORD_HERE")}</p>
                  <div className="input-container">
                    <label>{t("PASSWORD")}</label>
                    <div className="input-with-icon-container">
                      <div className="input-icon">
                        {/* <img src={require("../assets/img/v3/key.png")} alt="password" className="img-responsive" /> */}
                        <VIcon
                          icon={themeIcons.key}
                          className="theme-icon-size-20"
                        />
                      </div>
                      <input
                        type={newForgotLoginPasswordVisible ? 'text' : 'password'}
                        className="custom-input input-hidden"
                        id="newPassword"
                        name="newPassword"
                        autoComplete={"off"}
                        ref={resetPasswordRegister({
                          required: t("FIELD_REQUIRED"),
                          minLength: {
                            value: _MIN_PASSWORD_LENGTH,
                            message: t("MIN_CHARACTERS", { length: _MIN_PASSWORD_LENGTH })
                          },
                          pattern: {
                            value: /^[^ ]+$/i,
                            message: t("PLEASE_REMOVE_SPACES_AND_SPECIAL_CHARACTERS"),
                          },
                        })}
                        errors={resetPasswordErrors}
                        placeholder={t("ENTER_NEW_PASSWORD")}
                      />
                      <div className="invalid-feedback">
                        {resetPasswordErrors.newPassword
                          ? t(resetPasswordErrors.newPassword.message)
                          : ""}
                      </div>
                      <div className="input-hidden-icon" onClick={(e) => toggleHiddenForgot(e)}>
                        {/* <img src={isHiddenForgot} alt="password" className="img-responsive" /> */}
                        <VIcon
                          icon={isHiddenForgot}
                          className="theme-icon-size-20"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-container mt-4">
                    <label>{t("CONFIRM_PASSWORD")}</label>
                    <div className="input-with-icon-container">
                      <div className="input-icon">
                        {/* <img src={require("../assets/img/v3/lock.png")} alt="password" className="img-responsive" /> */}
                        <VIcon
                          icon={themeIcons.lockClosed}
                          className="theme-icon-size-20"
                        />
                      </div>
                      <input
                        type={confirmForgotLoginPasswordVisible ? 'text' : 'password'}
                        className="custom-input input-hidden"
                        id="confpassword"
                        name="confpassword"
                        autoComplete={"off"}
                        ref={resetPasswordRegister({
                          required: t("FIELD_REQUIRED"),
                          minLength: {
                            value: _MIN_PASSWORD_LENGTH,
                            message: t("MIN_CHARACTERS", {
                              length: _MIN_PASSWORD_LENGTH
                            })
                          },
                          validate: (value) =>
                            watch("newPassword") == "" ||
                            value == watch("newPassword") ||
                            "PASSWORD_MISMATCH",
                        })}
                        errors={resetPasswordErrors}
                        placeholder={t("CONFIRM_PASSWORD")}
                      />
                      <div className="invalid-feedback">
                        {resetPasswordErrors.confpassword
                          ? t(resetPasswordErrors.confpassword.message)
                          : ""}
                      </div>
                      <div className="input-hidden-icon" onClick={(e) => toggleHiddenForgotConfirm(e)}>
                        {/* <img src={isHiddenForgotConfirm} alt="password" className="img-responsive" /> */}
                        <VIcon
                          icon={isHiddenForgotConfirm}
                          className="theme-icon-size-20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button style={{ width: "100%" }} className="btn custom-btn mt-5" type="submit">
                    {t("CONFIRM")}
                  </button>
                </div>
              </div>
            </form>
          )}
        </ModalBody>

      </Modal>
      <Modal
        id="custom-modal"
        fade={true}
        isOpen={registerModalVisible}
        contentClassName="modal-brand modal-bottom"
        toggle={() => {
          setRegisterModalVisible(false);
        }}
        centered
      >
        <ModalBody>
          <div className="flex-box-centered mb-1">
            <h2 className="custom-modal-title mb-0 mr-3">{t("JOIN_US_NOW")}</h2>
            <div>
              <img src={require("../assets/img/v3/waving.png")} alt="waving" className="img-responsive" />
            </div>
          </div>
          <p className="pop-up-under-title-text mt-4 mb-4 text-center">{t("REGISTER_BY_REFERRAL_CODE")}</p>
          <div className="input-container">
            <label>{t("REFERRAL_CODE")}</label>
            <div className="input-with-icon-container">
              <input
                type='text'
                name='referralCode'
                className="custom-input"
                placeholder="eg. AbCd3fGH +"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
                ref={register({
                  required: t("FIELD_REQUIRED"),
                })}
                errors={registerErrors}
              />
              <div className="input-icon">
                {/* <img src={require("../assets/img/v3/referral-code-icon.png")} alt="referral-code" className="img-responsive" /> */}
                <VIcon
                  icon={themeIcons.code}
                  className="theme-icon-size-20"
                />
              </div>
            </div>
          </div>
          {!stringIsNullOrEmpty(registerPopupContent) && <div className="mt-3 referral-code-text">
            <span className="color-red">*</span>{parse(registerPopupContent)}
          </div>}
          <div className="mt-3 register-btn-container">
            <a className="btn custom-btn" style={{ marginTop: "24px" }} onClick={() => getUpline(referralCode)}>
              {t("REGISTER")}
            </a>
            <span>{t("LOGIN_TEXT")}<a className="login-now-text" onClick={() => { setRegisterModalVisible(false); setLoginModalVisible(true); }}>{t("LOGIN_NOW")}</a></span>
          </div>
          {/*<a className="btn custom-btn" style={{ marginTop: "24px" }} onClick={() => { setRegisterModalVisible(false); setLoginModalVisible(true) }}>*/}
          {/*  {t("LOGIN_TEXT")}*/}
          {/*</a>*/}
        </ModalBody>
      </Modal>
      <Modal
        id="custom-modal"
        className="modal-height-100"
        contentClassName="modal-brand modal-bottom"
        fade={false}
        isOpen={registerDetailsModalVisible}
        centered
      >
        <ModalBody style={{ padding: "40px 20px 28px" }}>
          {registerDetailsSteps == 1 && (
            <form>
              <div className="flex-spacebetween-row">
                <div>
                  <div className="modal-custom-header mb-4">
                    <div onClick={() => { registerDetailsBack() }}>
                      {/* <img src={require("../assets/img/v3/back-arrow.png")} alt="back" className="img-responsive" /> */}
                      <VIcon
                        icon={themeIcons.angleLeft}
                        className="theme-icon-size-20"
                      />
                    </div>
                    <h2 className="mb-0">{t("YOURE_INVITED")}</h2>
                  </div>
                  <p className="pop-up-under-title-text mb-5 text-center">{t("USER_INVITED", { uplineUsername: uplineDetail.username })}</p>
                  <div className="whatsapp-btn-div " onClick={() => window.open(registerWhatsAppUrl)}>
                      <div className="whatsapp-btn">
                        <div>
                          {t("REGISTER_WITH_WHATSAPP")}
                        </div>
                        <div>
                          <img src={require("../assets/img/v3/ri_whatsapp-line.svg")} alt="back" className="img-responsive" />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="whatsapp-or-div mt-2 mb-2"><span>OR</span></div>
                    <br />

                  <div className="input-container mt-3">
                    <label>{t("FULL_NAME")}</label>
                    <div className="input-with-icon-container">
                      <input
                        type='text'
                        name="registerFullName"
                        id="registerFullName"
                        value={registerFullName}
                        className="custom-input"
                        placeholder={t("FULL_NAME")}
                        ref={register({
                          required: "FIELD_REQUIRED",
                          pattern: {
                            value: /^[a-zA-Z]+[a-zA-Z ]*$/i,
                            message: "PLEASE_REMOVE_NUMBERS_AND_SPECIAL_CHARACTERS"
                          },
                        })}
                        errors={registerErrors}
                        onChange={(e) => setRegisterFullName(e.target.value)}
                      />
                      <div className="invalid-feedback">
                        {registerErrors.registerFullName
                          ? t(registerErrors.registerFullName.message)
                          : ""}
                      </div>
                      <div className="input-icon">
                        {/* <img src={require("../assets/img/v3/username.png")} alt="username" className="img-responsive" /> */}
                        <VIcon
                          icon={themeIcons.user}
                          className="theme-icon-size-20"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="input-container mt-3">
                    <label>{t("USERNAME")}</label>
                    <div className="input-with-icon-container">
                      <input
                        type='text'
                        name="registerUsername"
                        id="registerUsername"
                        value={registerUsername}
                        className="custom-input"
                        placeholder={t("USERNAME")}
                        ref={register({
                          required: t("FIELD_REQUIRED"),
                          minLength: {
                            value: _MIN_USERNAME_LENGTH,
                            message: t("", {
                              length: _MIN_USERNAME_LENGTH,
                            }),
                          },
                          validate: async (value) => await checkUsername(value) || (console.log('test'), "Username Taken"),
                          pattern: {
                            value: /^[a-zA-Z0-9]*$/i,
                            message: t("PLEASE_REMOVE_SPACES_AND_SPECIAL_CHARACTERS"),
                          },
                          minLength: {
                            value: 4,
                            message: t("MIN_CHARACTERS", { length: 4 }),
                          },
                        })}
                        onChange={(e) => setRegisterUsername(e.target.value)}
                        errors={registerErrors}
                      />
                      <div className="invalid-feedback">
                        {registerErrors.registerUsername
                          ? t(registerErrors.registerUsername.message)
                          : ""}
                      </div>
                      <div className="input-icon">
                        {/* <img src={require("../assets/img/v3/username.png")} alt="username" className="img-responsive" /> */}
                        <VIcon
                          icon={themeIcons.user}
                          className="theme-icon-size-20"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-container mt-4">
                    <label>{t("PHONE_NUMBER")}</label>
                    <div className="custom-phone-container">
                      <select
                        className="custom-select-container"
                        onChange={(e) => setSelectedPhonePrefix(e.target.value)}
                        value={selectedPhonePrefix}
                      >
                        {PhoneNumberPrefix.map((prefix, index) => {
                          return (
                            <option
                              key={index}
                              className={"phone-prefix-" + (index + 1)}
                              value={prefix.value}
                            >
                              {prefix.label}
                            </option>
                          );
                        })}
                      </select>

                      <div className="second">
                        <input
                          type="text"
                          placeholder="eg: 123456"
                          className="custom-phone-input"
                          name="registerPhoneNumber"
                          id="registerPhoneNumber"
                          value={registerPhoneNumber}
                          ref={register({
                            required: t("FIELD_REQUIRED"),
                            minLength: {
                              value: 8,
                              message: t("MIN_CHARACTERS", { length: 8 }),
                            },
                            pattern: {
                              value: /^[0-9]+$/g,
                              message:
                                t("MIN_CHARACTERS", { length: 8 }) +
                                " " +
                                t("AND") +
                                " " +
                                t("ONLY_NUMBERS"),
                            }
                          })}
                          onChange={(e) => setRegisterPhoneNumber(e.target.value)}
                          errors={registerErrors}
                        />
                      </div>
                    </div>
                    <div className="invalid-feedback">
                      {registerErrors.registerPhoneNumber
                        ? t(registerErrors.registerPhoneNumber.message)
                        : ""}
                    </div>
                  </div>

                  <label className="custom-checkbox mt-4">
                    <input
                      type="checkbox"
                      className="form-checkbox-label"
                      value={agreeTnC}
                      onChange={() => setAgreeTnC(!agreeTnC)}
                      checked={agreeTnC}
                    />
                    <span className="checkmark"></span>
                    <div>
                      {t("I_AGREE_TO")} {t("TNC_i8")} <a href="javascript:void(0);" onClick={() => (displayTnC())}>{t("TNC")}</a>
                    </div>
                  </label>
                  {showTncError && (
                    <div style={{ color: "red" }}>{t("REQUIRED")}</div>
                  )}

                  <label className="custom-checkbox mt-2">
                    <input
                      type="checkbox"
                      className="form-checkbox-label"
                      value={agreeAge18}
                      onChange={() => setAgreeAge18(!agreeAge18)}
                      checked={agreeAge18}
                    />
                    <span className="checkmark"></span>
                    {t("I_AGREE_LEGAL_AGE_18")}
                  </label>
                  {showAgeError && (
                    <div style={{ color: "red" }}>{t("REQUIRED")}</div>
                  )}

                </div>
                <div>
                  <button style={{ width: "100%" }} type="button" className="btn custom-btn mt-5" onClick={registerHandleSubmit(submitRegisterForm)}>
                    {t("CONTINUE")}
                  </button>
                </div>
              </div>
              {showTnC && (
                <form>
                  <div className="tnc-popup" style={{ padding: "40px 20px 28px" }}>
                    <div className="modal-custom-header" >
                      <div onClick={() => { setShowTnC(false); }}>
                        {/* <img src={require("../assets/img/v3/arrow-left.png")} alt="back" className="img-responsive" /> */}
                        <VIcon
                          icon={themeIcons.angleLeft}
                          className="theme-icon-size-20"
                        />
                      </div>
                      <div className="tnc-popup-header-v3">{t("TNC")}</div>
                    </div>

                    <div className="tnc-popup-body">
                      <label className="tnc-popup-label" htmlFor="terms-of-use">{t("TERMS_OF_USE")}</label>
                      <div id="terms-of-use">
                        <div>{t("TERMS_OF_USE_1")}</div>
                        <div>{t("TERMS_OF_USE_2")}</div>
                      </div>
                      <label className="tnc-popup-label" htmlFor="tnc-general">{t("GENERAL")}</label>
                      <div id="tnc-general">
                        <div className="tnc-list"> 1.<div className="col-12">{t("GENERAL_1")}</div></div>
                        <div className="tnc-list"> 2.<div className="col-12">{t("GENERAL_2")}</div></div>
                      </div>
                      <label className="tnc-popup-label" htmlFor="tnc-memberAcc">{t("MEMBER_ACCOUNT")}</label>
                      <div id="tnc-memberAcc">
                        <div className="tnc-list"> 1.<div className="col-12">{t("MEMBER_ACCOUNT_1")}</div></div>
                        <div className="tnc-list"> 2.<div className="col-12">{t("MEMBER_ACCOUNT_2")}</div></div>
                        <div className="tnc-list"> 3.<div className="col-12">{t("MEMBER_ACCOUNT_3")}</div></div>
                        <div className="tnc-list"> 4.<div className="col-12">{t("MEMBER_ACCOUNT_4")}</div></div>
                        <div className="tnc-list"> 5.<div className="col-12">{t("MEMBER_ACCOUNT_5")}</div></div>
                        <div className="tnc-list"> 6.<div className="col-12">{t("MEMBER_ACCOUNT_6")}</div></div>
                        <div className="tnc-list"> 7.<div className="col-12">{t("MEMBER_ACCOUNT_7")}</div></div>
                      </div>
                      <label className="tnc-popup-label" htmlFor="tnc-depositNwithdrawal">{t("DEPOSIT_AND_WITHDRAWAL")}</label>
                      <div id="tnc-depositNwithdrawal">
                        <div className="tnc-list"> 1.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_1")}</div></div>
                        <div className="tnc-list"> 2.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_2")}</div></div>
                        <div className="tnc-list"> 3.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_3")}</div></div>
                        <div className="tnc-list"> 4.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_4")}</div></div>
                        <div className="tnc-list"> 5.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_5")}</div></div>
                        <div className="tnc-list"> 6.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_6")}</div></div>
                        <div className="tnc-list"> 7.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_7")}</div></div>
                        <div className="tnc-list"> 8.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_8")}</div></div>
                        <div className="tnc-list"> 9.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_9")}</div></div>
                        <div className="tnc-list">10.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_10")}</div></div>
                        <div className="tnc-list">11.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_11")}</div></div>
                        <div className="tnc-list">12.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_12")}</div></div>
                        <div className="tnc-list">13.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_13")}</div></div>
                        <div className="tnc-list">14.<div className="col-12">{t("DEPOSIT_AND_WITHDRAWAL_14")}</div></div>
                        <div className="tnc-bullets-wrapper">
                          <div className="col-12 tnc-bullets">{t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_1")}</div>
                          <div className="col-12 tnc-bullets">{t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_2")}</div>
                          <div className="col-12 tnc-bullets">{t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_3")}</div>
                          <div className="col-12 tnc-bullets">{t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_4")}</div>
                          <div className="col-12 tnc-bullets">{t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_5")}</div>
                        </div>
                      </div>
                      <label className="tnc-popup-label" htmlFor="tnc-bettingTransactions" style={{ fontSize: "13px" }}>{t("MANAGEMENT_OF_BETTING_TRANSACTIONS")}</label>
                      <div id="tnc-bettingTransactions">
                        <div className="tnc-list"> 1.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_1")}</div></div>
                        <div className="tnc-list"> 2.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_2")}</div></div>
                        <div className="tnc-list"> 3.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_3")}</div></div>
                        <div className="tnc-list"> 4.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_4")}</div></div>
                        <div className="tnc-list"> 5.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_5")}</div></div>
                        <div className="tnc-list"> 6.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_6")}</div></div>
                        <div className="tnc-list"> 7.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_7")}</div></div>
                        <div className="tnc-list"> 8.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_8")}</div></div>
                        <div className="tnc-list"> 9.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_9")}</div></div>
                        <div className="tnc-list">10.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_10")}</div></div>
                        <div className="tnc-list">11.<div className="col-12">{t("MANAGEMENT_OF_BETTING_TRANSACTIONS_11")}</div></div>
                      </div>
                      <label className="tnc-popup-label" htmlFor="tnc-collusion">{t("COLLUSION_AND_ABUSE_ACTIVITIES")}</label>
                      <div id="tnc-collusion">
                        <div className="tnc-list"> 1.<div className="col-12">{t("COLLUSION_AND_ABUSE_ACTIVITIES_1")}</div></div>
                        <div className="tnc-list"> 2.<div className="col-12">{t("COLLUSION_AND_ABUSE_ACTIVITIES_2")}</div></div>
                        <div className="tnc-list"> 3.<div className="col-12">{t("COLLUSION_AND_ABUSE_ACTIVITIES_3")}</div></div>
                      </div>
                      <label className="tnc-popup-label" htmlFor="tnc-interruptions" style={{ fontSize: "13px" }}>{t("HANDLING_ON_SYSTEM_INTERRUPTIONS_ON_FORCE_MAJUERE")}</label>
                      <div id="tnc-interruptions">
                        <div className="tnc-list"> 1.<div className="col-12">{t("HANDLING_ON_SYSTEM_INTERRUPTIONS_ON_FORCE_MAJUERE_1")}</div></div>
                        <div className="tnc-list"> 2.<div className="col-12">{t("HANDLING_ON_SYSTEM_INTERRUPTIONS_ON_FORCE_MAJUERE_2")}</div></div>
                      </div>
                      <label className="tnc-popup-label" htmlFor="tnc-administrations">{t("ADMINISTRATIONS_OF_EXISTING_TERM_OF_USE")}</label>
                      <div id="tnc-administrations">
                        <div className="tnc-list"> 1.<div className="col-12">{t("ADMINISTRATIONS_OF_EXISTING_TERM_OF_USE_1")}</div></div>
                        <div className="tnc-list"> 2.<div className="col-12">{t("ADMINISTRATIONS_OF_EXISTING_TERM_OF_USE_2")}</div></div>
                      </div>
                      <label className="tnc-popup-label" htmlFor="tnc-customerService">{t("CUSTOMER_SERVICE")}</label>
                      <div id="tnc-customerService">
                        <div className="tnc-list"> 1.<div className="col-12">{t("CUSTOMER_SERVICE_1")}</div></div>
                      </div>
                      <div>
                        <div className="tnc-list" style={{ marginTop: "20px" }}><div>{t("ADDITIONAL_TNC_1")}</div></div>
                      </div>
                    </div>

                    <div className="tnc-btn-container">
                      <div style={{ paddingRight: "8px", width: "100%", display: "block" }}><button type="button" onClick={cancelButton} style={{ backgroundColor: "#FF334B" }} className="btn custom-btn tnc-button">{t('CANCEL')}</button></div>
                      <div style={{ paddingRight: "8px", width: "100%", display: "block" }}><button type="button" onClick={agreeButton} className="btn custom-btn tnc-button">{t('AGREE')}</button></div>
                    </div>
                  </div></form>
              )}
            </form>
          )}
          {registerDetailsSteps == 2 && (
            <div className="register-steps-pin">
              <div className="flex-spacebetween-row">
                <div>
                  <div className="modal-custom-header mb-4">
                    <div onClick={() => {
                      setRegisterDetailsSteps(1);
                      setAgreeAge18(false);
                      setAgreeTnC(false);
                    }}>
                      {/* <img src={require("../assets/img/v3/back-arrow.png")} alt="back" className="img-responsive" /> */}
                      <VIcon
                        icon={themeIcons.angleLeft}
                        className="theme-icon-size-20"
                      />
                    </div>
                    <h2 className="mb-0">{t("VERIFICATION_CODE")}</h2>
                  </div>
                  {showPhoneText && (
                    <p className="pop-up-under-title-text mb-5 text-center">{t("CODE_SENT_TO_NUMBER", { phoneNumber: selectedPhonePrefix + registerPhoneNumber })}</p>
                  )}
                  <div className="custom-pin-container tac-container" onClick={() => setShowKeyboard(true)}>
                    <PinInput
                      style={{ marginBottom: "35px" }}
                      ref={eleFinalPin}
                      length={6}
                      initialValue=""
                      secret={true}
                      focus={registerDetailsModalVisible && registerDetailsSteps == 2}
                      autoSelect={false}
                      disabled={true}
                      showSoftInputOnFocus={false}
                      onChange={(value, index) => {
                        setPinNumberFinalFocused(index);
                      }}
                      type="numeric"
                      inputMode="number"
                      inputStyle={{
                        border: "0",
                        margin: "0px 10px",
                        width: "40px",
                        height: "40px",
                        backgroundColor: "transparent",
                      }}
                      inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                      regexCriteria={/^[ 0-9_@./#&+-]*$/}
                    />
                  </div>
                  <a className="forgot-password mt-4" onClick={handleResetCode}>
                    {t("RESET_CODE_IN_SECONDS", { value: countdownPeriod })}
                  </a>
                </div>
              </div>
              <Keyboard
                layout={{
                  default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
                }}
                display={{
                  '{bksp}': "<i className='fas fa-backspace'></i>",
                }}
                theme={`keyboard keyboard-numpad keyboard-numpad-v3 no-bg`}
                keyboardRef={(r) => (keyboard.current = r)}
                onKeyPress={onKeyPress}
                disableButtonHold
                disableCaretPositioning
              />
              {countdownPeriod === 0 ? (
                <button className="btn btn-block btn-brand-gradient btn-done" onClick={handleResetCode}>
                  {t("RESEND")}
                </button>
              ) : (
                <button className="btn btn-block btn-brand-gradient btn-done" type="button" onClick={submitTransactionPin} disabled={enteredPinNumberFinal.length != 6}>
                  {t("CONFIRM")}
                </button>
              )
              }
            </div>
          )}
          {registerDetailsSteps == 3 && (
            <form>
              <div className="flex-spacebetween-row">
                <div>
                  <div className="modal-custom-header mb-4">
                    <div onClick={() => {
                      setRegisterDetailsSteps(1);
                      setAgreeAge18(false);
                      setAgreeTnC(false);
                    }}>
                      {/* <img src={require("../assets/img/v3/back-arrow.png")} alt="back" className="img-responsive" /> */}
                      <VIcon
                        icon={themeIcons.angleLeft}
                        className="theme-icon-size-20"
                      />
                    </div>
                    <h2 className="mb-0">{t("MANAGE_PASSWORD")}</h2>
                  </div>

                  <div className="input-container mt-4">
                    <label>{t("PASSWORD")}</label>
                    <div className="input-with-icon-container">
                      <div className="input-icon">
                        {/* <img src={require("../assets/img/v3/key.png")} alt="password" className="img-responsive" /> */}
                        <VIcon
                          icon={themeIcons.key}
                          className="theme-icon-size-20"
                        />
                      </div>

                      <input
                        type={newLoginPasswordVisible ? 'text' : 'password'}
                        autoComplete="new-password"
                        id="registerPassword"
                        name="registerPassword"
                        className="custom-input input-hidden"
                        placeholder={t("PASSWORD")}
                        ref={generatePasswordRegister({
                          required: t("FIELD_REQUIRED"),
                          minLength: {
                            value: _MIN_PASSWORD_LENGTH,
                            message: t("MIN_CHARACTERS", { length: _MIN_PASSWORD_LENGTH })
                          },
                          pattern: {
                            value: /^[^ ]+$/i,
                            message: t("PLEASE_REMOVE_SPACES_AND_SPECIAL_CHARACTERS"),
                          },
                        })}
                        errors={generatePasswordErrors}
                      />
                      <div className="input-hidden-icon" onClick={toggleHiddenRegister}>
                        {/* <img src={isHiddenRegister} alt="password" className="img-responsive" /> */}
                        <VIcon
                          icon={themeIcons.isHiddenRegister}
                          className="theme-icon-size-20"
                        />
                      </div>

                      <div className="invalid-feedback">
                        {generatePasswordErrors.registerPassword
                          ? t(generatePasswordErrors.registerPassword.message)
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="input-container mt-4">
                    <label>{t("CONFIRM_PASSWORD")}</label>
                    <div className="input-with-icon-container">
                      <div className="input-icon">
                        {/* <img src={require("../assets/img/v3/lock.png")} alt="password" className="img-responsive" /> */}
                        <VIcon
                          icon={themeIcons.lockClosed}
                          className="theme-icon-size-20"
                        />
                      </div>
                      <input
                        type={confirmLoginPasswordVisible ? 'text' : 'password'}
                        autoComplete={"off"}
                        id="registerConfirmPassword"
                        name="registerConfirmPassword"
                        className="custom-input input-hidden"
                        placeholder={t("CONFIRM_PASSWORD")}
                        ref={generatePasswordRegister({
                          required: t("FIELD_REQUIRED"),
                          minLength: {
                            value: _MIN_PASSWORD_LENGTH,
                            message: t("MIN_CHARACTERS", { length: _MIN_PASSWORD_LENGTH })
                          },
                          pattern: {
                            value: /^[^ ]+$/i,
                            message: t("PLEASE_REMOVE_SPACES_AND_SPECIAL_CHARACTERS"),
                          },
                          validate: value => (generatePasswordWatch("registerPassword") == '' || value == generatePasswordWatch("registerPassword")) || "PASSWORD_MISMATCH"
                        })}
                        errors={generatePasswordErrors}
                      />
                      <div className="invalid-feedback">
                        {generatePasswordErrors.registerConfirmPassword
                          ? t(generatePasswordErrors.registerConfirmPassword.message)
                          : ""}
                      </div>
                      <div className="input-hidden-icon" onClick={toggleHiddenRegisterConfirm}>
                        {/* <img src={isHiddenConfirm} alt="password" className="img-responsive" /> */}
                        <VIcon
                          icon={themeIcons.isHiddenConfirm}
                          className="theme-icon-size-20"
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div>
                  <button style={{ width: "100%" }} type="button" className="btn custom-btn mt-5" onClick={generatePasswordHandleSubmit(submitGeneratePassword)}>
                    {t("SUBMIT")}
                  </button>
                </div>
              </div>
            </form>
          )}

        </ModalBody>
      </Modal>
      <Modal
        id="success-modal"
        fade={true}
        isOpen={successPasswordModalVisible}
        toggle={() => {
          setSuccessPasswordModalVisible(false);
        }}
        centered
      >
        <ModalBody>
          <div className="modal-success-container">
            <img src={require("../assets/img/v3/successful.png")} alt="waving" className="img-responsive" />
            <h4 className="modal-success-title mt-4">
              {t("PASSWORD_CHANGED_SUCCESS")}
            </h4>
            <p className="modal-success-details mb-0">
              {t("PASSWORD_SUCCESSFULLY_CHANGED")}
            </p>
            <a className="btn custom-btn mt-4" onClick={() => setSuccessPasswordModalVisible(false)}>
              {t("GO_TO_HOME")}
            </a>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        id="success-modal"
        fade={true}
        isOpen={successRegisterModalVisible}
        toggle={() => {
          setSuccessRegisterModalVisible(false);
        }}
        centered
      >
        <ModalBody>
          <div className="modal-success-container">
            <img src={require("../assets/img/v3/successful.png")} alt="waving" className="img-responsive" />
            <h4 className="modal-success-title mt-4">
              {t("CONGRATULATIONS")}
            </h4>
            <p className="modal-success-details mb-0">{t("ACCOUNT_CREATED")} <br />{t("PLAY_TOGETHER")}</p>
            <a className="btn custom-btn mt-4" onClick={goToHome}>
              {t("GO_TO_HOME")}
            </a>
          </div>
        </ModalBody>
      </Modal>

      {/*<Modal*/}
      {/*  id="custom-modal"*/}
      {/*  contentClassName="modal-brand modal-bottom"*/}
      {/*  fade={true}*/}
      {/*  isOpen={walletModalVisible}*/}
      {/*  toggle={() => {*/}
      {/*    setWalletModalVisible(false);*/}
      {/*  }}*/}
      {/*  centered*/}
      {/*>*/}
      {/*  <ModalBody>*/}
      {/*    <div className="flex-box-centered mb-4">*/}
      {/*      <h2 className="wallet-modal-title mb-0">{activeProviderName}</h2>*/}
      {/*    </div>*/}
      {/*    <div className="game-wallet-container mb-4">*/}
      {/*      <div className="first">*/}
      {/*        <h6 className="mb-1">{t("MAIN_WALLET")}</h6>*/}
      {/*        <p className="mb-0">$ {numberWithCurrencyFormat2((isNaN(currentWalletBalance) ? 0 : currentWalletBalance), 2, true)}</p>*/}
      {/*      </div>*/}
      {/*      <div className="second">*/}
      {/*        <h6 className="mb-1">{activeProviderName}</h6>*/}
      {/*        <p className="mb-0">$ {numberWithCurrencyFormat2((isNaN(parseFloat(providerWalletData.balance)) ? 0 : parseFloat(providerWalletData.balance)), 2, true)}</p>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <h5 className="game-wallet-amount-label mb-2">{t("AMOUNT")}</h5>*/}
      {/*    <form onSubmit={handleSubmitPopupTransfer(submitTransferForm)}>*/}
      {/*      <div className="game-wallet-amount-container mb-4">*/}
      {/*        <div className="first">*/}
      {/*          <input*/}
      {/*            type="number"*/}
      {/*            readOnly*/}
      {/*            className="game-wallet-input"*/}
      {/*            placeholder={"0.00"}*/}
      {/*            name="amount"*/}
      {/*            onClick={(e) => {*/}
      {/*              setCurrentInput(e.target);*/}
      {/*            }}*/}
      {/*            ref={registerPopupTransfer({*/}
      {/*              required: t("PLEASE_ENTER_AMOUNT"),*/}
      {/*              validate: {*/}
      {/*                minValue: (value) =>*/}
      {/*                  parseFloat(value) > 0 ||*/}
      {/*                  t("PLEASE_ENTER_VALUE_MORE_THAN_ZERO"),*/}
      {/*              },*/}
      {/*            })} />*/}
      {/*        </div>*/}
      {/*        <button type="submit" className="game-wallet-btn second">*/}
      {/*          {t("TRANSFER")}*/}
      {/*        </button>*/}
      {/*      </div>*/}
      {/*    </form>*/}

      {/*    <div className="game-wallet-new-balance-container">*/}
      {/*      <h3 className="">{activeProviderName} {t("NEW_BALANCE")}: {watchPopupTransfer("amount") > 0 ? numberWithCurrencyFormat2(parseFloat(providerWalletData.balance) + parseFloat(watchPopupTransfer("amount")), 2, true) : "-"}</h3>*/}
      {/*      {providerWalletData.productConversionRate !== 0 && providerWalletData.productConversionRate !== 1 && <p>{t("CONVERSION_RATE") + " : 1"}&#3647; <i className="fas fa-arrow-right"></i> {numberWithCurrencyFormat(providerWalletData.productConversionRate, 2, true)}</p>}*/}
      {/*      {(providerWalletData.bonus > 0 || providerWalletData.commission > 0 || providerWalletData.clubFee > 0) &&*/}
      {/*        <p className="mb-0">*/}
      {/*          {providerWalletData.commission > 0 && t("COMMISSION") + " " + "(%):" + numberWithCurrencyFormat(providerWalletData.commission, 2, true) + ((providerWalletData.bonus > 0 || providerWalletData.clubFee > 0) ? " / " : "")}*/}
      {/*          {providerWalletData.bonus > 0 && t("BONUS") + " " + "(%):" + numberWithCurrencyFormat(providerWalletData.bonus, 2, true) + (providerWalletData.clubFee > 0 ? " / " : "")}*/}
      {/*          {providerWalletData.clubFee > 0 && t("CLUB_FEE") + " " + "(%):" + numberWithCurrencyFormat(providerWalletData.clubFee, 2, true)}*/}
      {/*        </p>*/}
      {/*      }*/}
      {/*    </div>*/}
      {/*    {gameIsApp && gameAppDeepLink === "" && (!stringIsNullOrEmpty(providerWalletData.username) && !stringIsNullOrEmpty(providerWalletData.password)) &&*/}
      {/*      <>*/}
      {/*        <div className="game-wallet-account-container mt-4 mb-4 pt-2 pb-2">*/}
      {/*          <div className="first"></div>*/}
      {/*          <div className="second">{activeProviderName + " " + t("ACCOUNT")}</div>*/}
      {/*          <div className="third"></div>*/}
      {/*        </div>*/}
      {/*        <div className="game-wallet-account-details">*/}
      {/*          <div className="game-account-details-box">*/}
      {/*            <div className="flex-baseline">*/}
      {/*              <p className="mb-0" style={{ minWidth: "50px" }}>{t("USERNAME") + ":"} </p><p><input className="text-for-copy" ref={usernameRef} value={providerWalletData.username} readOnly /></p>*/}
      {/*            </div>*/}
      {/*            <div>*/}
      {/*              <img src={require("../assets/img/v3/copy.png")} alt="copy" className="img-responsive" onClick={() => copyText("username")} />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="game-account-details-box mt-3">*/}
      {/*            <div className="flex-baseline">*/}
      {/*              <p className="mb-0" style={{ minWidth: "50px" }}>{t("PASSWORD") + ":"} </p><p><input className="text-for-copy" ref={passwordRef} value={providerWalletData.password} readOnly /></p>*/}
      {/*            </div>*/}
      {/*            <div className="game-account-icon-box">*/}
      {/*              <div>*/}
      {/*                <img src={require("../assets/img/v3/refresh.png")} alt="copy" className="img-responsive" onClick={() => resetProductPassword()} />*/}
      {/*              </div>*/}
      {/*              <div>*/}
      {/*                <img src={require("../assets/img/v3/copy.png")} alt="copy" className="img-responsive" onClick={() => copyText("password")} />*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="game-account-details-box mt-3">*/}
      {/*            <span className="alert-text text-color-green">{showCopyAlert ? alertMessage : " "}</span>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </>*/}
      {/*    }*/}
      {/*     {gameHasLobby ?*/}
      {/*      <a*/}
      {/*        className="btn custom-btn mt-5"*/}
      {/*        onClick={() => _history.push({*/}
      {/*          pathname: WebUrl._URL_PROVIDER_GAME_LOBBY,*/}
      {/*          search: "?name=" + activeProviderName*/}
      {/*        })}*/}
      {/*      >*/}
      {/*        {t("TO_LOBBY")}*/}
      {/*      </a> :*/}
      {/*      <a className="btn custom-btn mt-5" onClick={() => startGame(activeSoloGame)}>*/}
      {/*        {t("START_GAME")}*/}
      {/*      </a>*/}
      {/*    } */}
      {/*    {!gameIsApp || (gameIsApp && gameAppDeepLink != "") ? (*/}
      {/*      <>*/}
      {/*        {gameHasLobby ?*/}
      {/*          <button*/}
      {/*            className="btn custom-btn mt-5 w-100"*/}
      {/*            onClick={() => _history.push({*/}
      {/*              pathname: WebUrl._URL_PROVIDER_GAME_LOBBY,*/}
      {/*              search: "?name=" + activeProviderName*/}
      {/*            })}*/}
      {/*          >*/}
      {/*            {t("TO_LOBBY")}*/}
      {/*          </button> :*/}
      {/*          <button className="btn custom-btn mt-5 w-100" onClick={() =>*/}
      {/*            //startGame(activeSoloGame)*/}
      {/*                  _dispatch(*/}
      {/*                    startGame(activeSoloGame)*/}
      {/*                  )*/}
      {/*          }>*/}
      {/*            {t("START_GAME")}*/}
      {/*          </button>*/}
      {/*        }*/}
      {/*      </>*/}
      {/*    ) : (*/}
      {/*      <button*/}
      {/*        className="btn custom-btn mt-5 w-100"*/}
      {/*        onClick={() => {*/}
      {/*          if (window.ReactNativeWebView) {*/}
      {/*            window.ReactNativeWebView.postMessage(*/}
      {/*              JSON.stringify({*/}
      {/*                url: gameAppUrl,*/}
      {/*                packageKeyword: gameAppPackageNameKeyword,*/}
      {/*              }));*/}
      {/*          } else {*/}
      {/*            window.open(gameAppUrl);*/}
      {/*          }*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        {t("DOWNLOAD_APP")}*/}
      {/*      </button>*/}
      {/*    )}*/}
      {/*  </ModalBody>*/}
      {/*</Modal>*/}

      <Modal
        id="nav-modal"
        fade={true}
        isOpen={navVisible}
        toggle={() => {
          setNavVisible(false);
        }}
      >
        <ModalBody>
          <div className="nav-title-container">
            <div className="nav-title mb-0">
              <img src={"/img/i8.live_2024" + (themeName === "light" ? "_alt" : "") + ".svg"} alt="i8" className="i8-logo-menu img-responsive" /> 
            </div>
            {isLoggedIn && <><div className="custom-hr mt-4 mb-4"></div>
              <div className="navList-container">
                {navList1 &&
                  navList1.length &&
                  map(navList1, (item, index) => {
                    return (
                      <a
                        key={index}
                        className="nav-items"
                        onClick={() => {
                          if (item.text === "VIP_EVENT") {
                            setShowVipPinEntry(true);
                            setNavVisible(false);
                          }
                          else {
                            _history.push(item.url)
                          }
                        }}
                      >
                        <div className="first">
                          {/* <img src={item.icon} alt={item.text} className="img-responsive" /> */}
                          <VIcon
                            icon={item.vicon}
                            className={"theme-icon-size-15 vicon1" + index}
                          />
                        </div>
                        <div className="second">
                          {t(item.text)}
                        </div>
                      </a>
                    );
                  })
                }
              </div></>}
            <div className="custom-hr mt-4 mb-4"></div>
            <div className="navList-container">
              {navList2 &&
                navList2.length &&
                map(navList2
                  .filter((x) => (isLoggedIn ? x.text !== "CHECK_IN" : x)),
                  (item, index) => {
                  return (
                    <a key={index} className="nav-items align-items-center" onClick={() => _history.push(item.href)}>
                      <div className="first">
                        {/* <img src={item.icon} alt={item.text} className="img-responsive" /> */}
                        <VIcon
                          icon={item.vicon}
                          className={"theme-icon-size-15 vicon2" + index}
                        />
                      </div>
                      <div className="second">
                        {t(item.text)}
                      </div>
                    </a>
                  );
                })
              }
            </div>
            <div className="custom-hr mt-4 mb-4"></div>
            {!window.ReactNativeWebView &&
              <>
                <div className="navList-container">
                  <div className="nav-items"
                    onClick={() =>
                      (window.location.href.includes("staging") || window.location.href.includes("localhost")) ?
                        window.open("http://download.i8global.com/?lang=" + activeLanguage + "&country=my", "_blank")
                        :
                        window.open("https://download.i8king.com/?lang=" + activeLanguage + "&country=my", "_blank")
                    }
                  >
                    <div className="first">
                      {/* <img src={require("../assets/img/v3/icon-mobile.svg")} alt="Download APP" className="img-responsive" /> */}
                      <VIcon
                        icon={themeIcons.mobile}
                        className="theme-icon-size-15"
                      />
                    </div>
                    <div className="second">
                      {t("DOWNLOAD_GAME_APP")}
                    </div>
                  </div>
                </div>
                <div className="custom-hr mt-4 mb-4"></div>
              </>
            }
            {isLoggedIn &&
              <>
                <div className="navList-container">
                  <div className="nav-items" onClick={() => { handleLogout() }}>
                    <div className="first">
                      {/* <img src={require("../assets/img/v3/logout-icon.svg")} alt="Logout" className="img-responsive" /> */}
                      <VIcon
                        icon={themeIcons.settingsLogout}
                        className="theme-icon-size-15"
                      />
                    </div>
                    <div className="second">
                      {t("LOGOUT")}
                    </div>
                  </div>
                </div>
                <div className="custom-hr mt-4 mb-4"></div>
              </>
            }
            <div className="social-icon-container">
              <div className="social-icon-title mb-2">
                {t("FOLLOW_US")}
              </div>
              <ul className="list-inline social-list">
                {socialMediaList &&
                  socialMediaList.length &&
                  map(socialMediaList, (item, index) => {
                    return (
                      <li key={index}>
                        <a href={item.link}>
                          <img src={item.image} alt='social media' className="img-responsive" style={{ width: "22px", height: "22px" }} />
                        </a>
                      </li>
                    );
                  })
                }
              </ul>
              <div className="custom-hr mt-4 mb-4"></div>
              <div className="switch-theme-container" onClick={(e) => { e.preventDefault(); updateMemberTheme(); }}>
                <div className="member-theme-title mb-2">
                  {t("THEME_COLOR")}
                </div>
                <div className="display-flex align-items-center justify-content-space-between">
                  <div className="switch">
                    <input type="checkbox" checked={themeName === "light"} />
                    <span className="slider round"></span>
                  </div>
                  <div className="theme-text">{t((themeName === "light" ? "LIGHT" : "DARK") + "_THEME")}</div>
                </div>
              </div>
            </div>
            {/*<div className="custom-hr mt-4 mb-4"></div>*/}
            {/*<div className="custom-select-container" onClick={() => setShowNavCountry(!showNavCountry)}>
              <div className="custom-select-field">
                {selectionOption.length != 0 ? (
                  <div>
                    <div>
                      <img
                        src={selectionOption.icon}
                        alt={selectionOption.name}
                      />
                    </div>
                    <div>
                      {selectionOption.name}
                    </div>
                  </div>
                ) : (
                  <div>
                    {t("SELECT_COUNTRY")}
                  </div>
                )}
                <div>
                  {showDropdown ? (
                    <img
                      src={require("../assets/img/v3/arrow-up.png")}
                      alt='arrow'
                    />
                  ) : (
                    <img
                      src={require("../assets/img/v3/arrow-down.png")}
                      alt='arrow'
                    />
                  )}

                </div>
              </div>
              <div className="country-dropdown-container" style={showNavCountry ? { display: 'block' } : { display: 'none' }}>
                {countryList &&
                  countryList.length &&
                  map(countryList, (item, index) => {
                    return (
                      <div key={index} className="country-dropdown-items" onClick={() => setSelectionNavOption({ name: item.name, icon: item.icon })}>
                        <div>
                          <img
                            src={item.icon}
                            alt={item.name}
                          />
                        </div>
                        <div>
                          {item.name}
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>*/}
          </div>
        </ModalBody>
      </Modal>
      <Modal
        fade={false}
        contentClassName="modal-brand modal-center"
        id="modal-center"
        isOpen={isTransferSuccess}
        toggle={() => {
          setIsTransferSuccess(false);
        }}
        centered
      >
        <ModalBody>
          <div className="modal-custom-header justify-content-center" style={{
            display: 'flex',
            flexDirection: 'column',
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "29px",
            color: "#EFEFEF"
          }}>
            <h2
              className="mb-0"
            >
              {t("TRANSFER_SUCCESS")}
            </h2>
            <div className="text-center mt-4">
              <img
                src={require("../assets/img/v3/approved-modal.png")}
                alt={t("APPROVED")}
              />
            </div>
            <div className="text-center mt-4">
              {t("PAID_DEPOSIT", { amount: paidAmount })}
            </div>
          </div>
          <table className="table text-brand table-reload-detail text-white">
            <tr>
              <th>{t("DATE")}</th>
              <th style={{ textAlign: "right" }}>{transferDate}</th>
            </tr>
            <tr>
              <th>{t("REFERENCE_NUMBER")}</th>
              <th style={{ textAlign: "right" }}>{transcactionNumber}</th>
            </tr>
          </table>
          <button
            className="btn btn-block btn-brand-gradient"
            onClick={() => {
              setIsTransferSuccess(false);
            }}
          >
            {t("DONE")}
          </button>
        </ModalBody>
      </Modal>

      {currentInput &&
        <Numpad2
          currentBalance={_userData.walletBalance}
          currentInput={currentInput}
          setCurrentInput={(e) => {
            setCurrentInput(e);
          }}
          contentClassName="popup-transfer-numpad"
        />
      }


      <Modal
        fade={false}
        contentClassName="modal-brand modal-bottom modal-numpad popup-transfer-numpad"
        backdrop={true}
        isOpen={showVipPinEntry}
        centered
      >
        <ModalBody>
          <div className="display-wrapper">
            <button
              type="button"
              style={{
                alignSelf: "flex-end",
                backgroundColor: "transparent",
                border: "none",
                marginRight: "35px",
                fontSize: "2rem",
                color: "#fff",
                marginTop: "-15px",
                float: "right",
                fontWeight: "400",
              }}
              onClick={() => {
                clearInterval(false);
                setShowVipPinEntry(false);
              }}
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span>&times;</span>
            </button>
            <strong className="text-brand display-value" style={{ fontSize: "20px" }}>
              {t("ENTER_YOUR_PIN")}
            </strong>
            <PinInput
              style={{ marginTop: "37px" }}
              ref={eleFinalPin}
              length={4}
              initialValue=""
              secret={true}
              focus={showVipPinEntry}
              autoSelect={false}
              disabled={true}
              onChange={(value, index) => {
                setPinNumberFinalFocused(index);
              }}
              type="numeric"
              inputMode="number"
              inputStyle={{
                border: "0",
                margin: "0px 10px",
                width: "40px",
                height: "40px",
                backgroundColor: "transparent",
                borderBottom: "2px solid #fff",
              }}
              inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
              regexCriteria={/^[ 0-9_@./#&+-]*$/}
            />
            {/*<strong className="text-brand display-value" style={{ fontSize: "10px", letterSpacing: "0.33px" }} onClick={() => _history.push(WebUrl._URL_FORGET_PIN)}>Forgot PIN?</strong>*/}


            <div
              className="text-brand text-center"
              style={{ marginTop: "10px" }}
              onClick={() => {
                setForgetPin(true);
                sendPinCode();
              }}
            >
              {t("RESET_PIN")}
            </div>
            {smsSent && (
              <p
                className="text-brand"
                style={{ textAlign: "center", marginTop: "5px" }}
              >
                {t("PIN_SENT")} {t("RESEND_IN")} {countdownPeriod} {t("SECOND")}
              </p>
            )}
            {showAlert && (
              <div
                className="content content-full-width"
                style={{
                  position: "fixed",
                  top: "10px",
                  left: "0",
                  width: "100%",
                }}
              >
                <div className="container">
                  <Alert color="danger">{t("WAIT_TO_RESEND")}</Alert>
                </div>
              </div>
            )}
            <div
              className="invalid-feedback"
              style={{ fontSize: "12px", marginTop: "1rem" }}
            >
              {invalidPin && t(errorMessage)}
              {!invalidPin && <span>&nbsp;</span>}
            </div>
          </div>
          <Keyboard
            layout={{
              default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
            }}
            display={{
              '{bksp}': "<i className='fas fa-backspace'></i>",
            }}
            theme={`keyboard keyboard-numpad keyboard-numpad-v3`}
            keyboardRef={(r) => (keyboard.current = r)}
            onKeyPress={onVerifyPinKeyPress}
            disableButtonHold
            disableCaretPositioning
          />
        </ModalBody>
      </Modal>

      <Modal
        fade={false}
        contentClassName="modal-brand modal-bottom modal-numpad popup-transfer-numpad"
        backdrop={true}
        isOpen={showCreateShareholderPin}
        centered
      >
        <ModalBody>
          <form>
            <div className="display-wrapper">
              <button type="button" style={{
                alignSelf: "flex-end",
                backgroundColor: "transparent",
                border: "none",
                marginRight: "15px",
                fontSize: "2rem",
                color: "#002e6c",
                marginTop: "-15px",
                fontWeight: "bold",
              }}
                onClick={() => { setShowCreateShareholderPin(false); }}
                data-dismiss="modal"
                aria-hidden="true">
                <span>&times;</span>
              </button>
              <strong className="text-brand display-value" style={{ fontSize: "20px" }}>
                {t("NO_SHAREHOLDER_PIN_FOUND")}
              </strong>
              <div className="text-brand text-center" style={{ marginTop: "10px" }}>
                {t("ENTER_YOUR_PIN")}
              </div>
              <PinInput
                ref={elePin}
                length={4}
                initialValue=""
                secret={true}
                focus={showCreateShareholderPin}
                autoSelect={false}
                disabled={true}
                type="numeric"
                onChange={(value, index) => {
                  setPinNumberFocused(index);
                }}
                onComplete={(value) => adjustFocus()}
                inputMode="numeric"
                inputStyle={{
                  border: "0",
                  margin: "0px 10px",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "transparent",
                }}
                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                regexCriteria={/^[ 0-9_@./#&+-]*$/}
              />
              <div className="text-brand text-center" style={{ marginTop: "10px" }}>
                {t("CONFIRM_YOUR_PIN")}
              </div>
              <PinInput
                ref={eleConfirm}
                length={4}
                initialValue=""
                secret={true}
                focus={false}
                autoSelect={false}
                disabled={true}
                type="numeric"
                onChange={(value, index) => {
                  setPinNumberConfirmFocused(index);
                }}
                inputMode="numeric"
                inputStyle={{
                  border: "0",
                  margin: "0px 10px",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "transparent",
                }}
                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                regexCriteria={/^[ 0-9_@./#&+-]*$/}
              />
              <div
                className="invalid-feedback"
                style={{ fontSize: "12px", marginTop: "1rem" }}
              >
                {invalidPin && t(errorMessage)}
                {!invalidPin && <span>&nbsp;</span>}
              </div>
            </div>
            <Keyboard
              layout={{
                default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
              }}
              display={{
                "{bksp}": "<i class ='fas fa-backspace'></i>",
              }}
              theme={`keyboard keyboard-numpad keyboard-numpad-v3`}
              keyboardRef={(r) => (keyboard.current = r)}
              onKeyPress={onCreatePinKeyPress}
              disableButtonHold
              disableCaretPositioning
            />
          </form>
        </ModalBody>
      </Modal>

      {openMiniGameModal && (
        <div className="cny-video">
          <div className="cny-video-box">
            <div className="mini-games-modal">
              <div className="mini-games-dialog">
                <div className="mini-games-header">
                  <img src={require("../assets/img/mini-games/MiniGamesHeader.png")} />
                  <button className="mini-close-button"
                    onClick={() => {
                      setOpenMiniGameModal(false);
                      setCoinMiniGameUrl("");
                      setKenoMiniGameUrl("");
                      setTaiXiuMiniGameUrl("");
                      setCurrentActiveTab("COINMINI");
                      setMiniGamesFirstClicked(["COINMINI"]);
                      setIsLoading(true);
                    }}
                  ></button>
                </div>
                <div className="mini-games-body">
                  <Nav tabs className="mini-games-tab">
                    <NavItem>
                      <NavLink
                        className={
                          "mini-games-items " +
                          classnames({
                            selected:
                              currentActiveTab === "KENOMINI"
                          })}
                        onClick={() => {
                          toggle("KENOMINI");
                        }}
                      >
                        <img
                          src={require("../assets/img/mini-games/KenoMini-Icon.png")}
                          loading="lazy" />
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          "mini-games-items " +
                          classnames({
                            selected:
                              currentActiveTab === "COINMINI"
                          })}
                        onClick={() => {
                          toggle("COINMINI");
                        }}
                      >
                        <img
                          src={require("../assets/img/mini-games/CoinMini.png")}
                          loading="lazy" />
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          "mini-games-items " +
                          classnames({
                            selected:
                              currentActiveTab === 'TAIXIUMINI'
                          })}
                        onClick={() => {
                          toggle('TAIXIUMINI');
                        }}
                      >
                        <img
                          src={require("../assets/img/mini-games/TaiXiumini.png")}
                          loading="lazy" />
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent
                    activeTab={currentActiveTab}
                    className="mini-games-section"
                  >
                    <TabPane
                      tabId="KENOMINI"
                      className="mini-games-content"
                      style={currentActiveTab === 'KENOMINI' ? { display: "block !important" } : { display: "none !important", opacity: 0 }
                      }>
                      <div id="KENOMINI" className="mini-games-content">
                        {
                          isLoading &&
                          <div className="loading">
                            <Spinner animation="border" role="status"></Spinner>
                          </div>
                        }
                        <iframe
                          className="iframe"
                          loading="lazy"
                          frameBorder="0"
                          width="248"
                          height="227"
                          title="Keno Mini"
                          src={kenoMiniGameUrl}
                        >
                        </iframe>
                      </div>
                    </TabPane>
                    <TabPane
                      tabId="COINMINI"
                      className="mini-games-content"
                      style={currentActiveTab === 'COINMINI' ? { display: "block !important" } : { display: "none !important", opacity: 0 }}>
                      <div id="COINMINI" className="mini-games-content">
                        {
                          isLoading &&
                          <div className="loading">
                            <Spinner animation="border" role="status"></Spinner>
                          </div>
                        }
                        <iframe
                          className="iframe"
                          loading="lazy"
                          width="248"
                          height="227"
                          title="Coin Mini"
                          src={coinMiniGameUrl}
                        >
                        </iframe>
                      </div>
                    </TabPane>
                    <TabPane
                      tabId="TAIXIUMINI"
                      className="mini-games-content"
                      style={currentActiveTab === 'TAIXIUMINI' ? { display: "block !important" } : { display: "none !important", opacity: 0 }}>
                      <div id="TAIXIUMINI" className="mini-games-content">
                        {
                          isLoading &&
                          <div className="loading">
                            <Spinner animation="border" role="status"></Spinner>
                          </div>
                        }
                        <iframe
                          className="iframe"
                          loading="lazy"
                          frameBorder="0"
                          width="248"
                          height="227"
                          title="TaiXiu Mini"
                          src={taiXiuMiniGameUrl}
                        >
                        </iframe>
                      </div>
                    </TabPane>
                  </TabContent>
                  <div className="more-game-img"
                    onClick={() => {
                      if (!isEmpty(games93Connect)) {
                        /*startGame(games93Connect);*/
                        _dispatch(
                          startGame(games93Connect)
                        );
                      }
                    }}
                  >
                    <img src={require("../assets/img/mini-games/moreGames.png")} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {JSON.parse(localStorage.getItem(SessionKey._ISSHOWMINIGAMETRIGGER)) &&
        <>
          <Draggable
            bounds={{ left: 0, top: 250 - window.screen.height, right: 0, bottom: 0 }}
            onDrag={(e, position) => {
              onControlledDrag(e, position);
              setIsDragging(true);
            }}
            onStop={() => {
              if (!isDragging) {
                setOpenMiniGameModal(true);
                startMiniGame(currentActiveTab, "m");
              }
              setIsDragging(false)
            }}
          >

            <div className="cny-pop-trigger" ref={miniGameRef}>
              <img src={require("../assets/img/mini-games/App_"
                + (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined'
                  ? localStorage.getItem(SessionKey._LANGUAGE) == 'en' || localStorage.getItem(SessionKey._LANGUAGE) == 'zh' || localStorage.getItem(SessionKey._LANGUAGE) == 'ms'
                    ? localStorage.getItem(SessionKey._LANGUAGE)
                    : 'en'
                  : 'en')
                + ".png")}
              />
            </div>
          </Draggable>

          <Draggable
            bounds="body"
            position={controlledPosition}
            onStop={() => {
              setShowMiniGameTrigger(false);
              localStorage.setItem(SessionKey._ISSHOWMINIGAMETRIGGER, false);
            }}
          >
            <div className="cny-pop-trigger" ref={miniGameCloseButtonRef}>
              <div className="cny-trigger-close">
                <img src={require("../assets/img/cny/Close_Icon.svg")} style={{ width: "20px" }} />
              </div>
            </div>
          </Draggable>

        </>
      }
      <CustomSpinWheelContainer />
      <BannerPopupModal />
      {showNonSeamlessTransferConfirmation && <NonSeamlessGameWalletModal />}
    </div>
  );
};

export default withRouter(Login);